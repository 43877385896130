import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';

import {
  sectorsDropdown,
  heroDropdown,
  industriesDropdown,
  quartersDropdown,
  iirQuartersDropdown,
  sectorReport,
  dividendsReport,
  individualReport,
  uuuReport,
  otherReport,
  underDollarReport,
  movingAvgReport,
  recentSecDetailReport,
  recentSecSymbolReport,
  recentSecDailyReport,
  recentMutualFundsDailyReport,
  dailyReport,
  decreasedDowTheoryReport,
  increasedDowTheoryReport,
  recentSecYearlySymbolReport,
  recentSecYearlyDetailReport,
  insidersReport,
  insidersSymbolSummaryReport,
  newlyBoughtReport,
  trendingStocksReport,
  trendingStocksActivesReport,
  noOfWeeksDropdown,
  noOfWeeksForWeeklyYTDDropdown,
  noOfDowDaysDropdown,
  fundNamesDropdown,
  countryDropdown,
  insiderTitlesDropdown,
  seriesNamesDropdown,
  mutualFundSymbolsDropdown,
  recentMutualFundsSymbolReport,
  recentByMutualFundReport,
  mutualFundFundSymbolsDropdown,
  userReferrals,
  increasedDowDailyTheoryReport,
  decreasedDowDailyTheoryReport,
  increasedDowDailyPriceAndVolTheoryReport,
  decreasedDowDailyPriceAndVolTheoryReport,
  noOfDowDaysForPriceAndVolDropdown,
  recentSECByTrxValReport,
  getTopTwentyFiveSymbolsReport,
  dayTrendBySymbol,
  recommendationBySymbol,
  newsBySymbol,
  companyProfileBySymbol,
} from './queries';

export const getDailyReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: dailyReport,
  }).then(({ data }) => data.dailyReport);

export const getRecentSECDailyReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: recentSecDailyReport,
  }).then(({ data }) => data.recentSECDailyReport);

export const getRecentMutualFundsDailyReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: recentMutualFundsDailyReport,
  }).then(({ data }) => data.recentMutualFundsDailyReport);
export const getUserReferrals = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: userReferrals,
  }).then(({ data }) => data.userReferrals);

export const getRecentMutualFundsSymbolReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: recentMutualFundsSymbolReport,
  }).then(({ data }) => data.recentMutualFundsSymbolReport);

export const getRecentByMutualFundReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: recentByMutualFundReport,
  }).then(({ data }) => data.recentByMutualFundReport);

export const getRecentSECSymbolReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: recentSecSymbolReport,
  }).then(({ data }) => data.recentSECSymbolReport);

export const getRecentSECDetailReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: recentSecDetailReport,
  }).then(({ data }) => data.recentSECDetailReport);

export const getRecentSECByTrxValReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: recentSECByTrxValReport,
  }).then(({ data }) => data.recentSECByTrxValReport);

export const getTopTwentyFiveSymbolsData = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: getTopTwentyFiveSymbolsReport,
  }).then(({ data }) => data.getTopTwentyFiveSymbolsReport);

export const getRecentSECYearlySymbolReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: recentSecYearlySymbolReport,
  }).then(({ data }) => data.recentSECYearlySymbolReport);

export const getRecentSECYearlyDetailReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: recentSecYearlyDetailReport,
  }).then(({ data }) => data.recentSECYearlyDetailReport);

export const getMovingAvgReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: movingAvgReport,
  }).then(({ data }) => data.movingAvgReport);

export const getUnderDollarReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: underDollarReport,
  }).then(({ data }) => data.underDollarReport);

export const getOtherReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: otherReport,
  }).then(({ data }) => data.otherReport);

export const getUuuReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: uuuReport,
  }).then(({ data }) => data.uuuReport);

export const getDecreasedDowTheoryReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: decreasedDowTheoryReport,
  }).then(({ data }) => data.decreasedDowTheoryReport);

export const getIncreasedDowTheoryReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: increasedDowTheoryReport,
  }).then(({ data }) => data.increasedDowTheoryReport);

export const getNewlyBoughtReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: newlyBoughtReport,
  }).then(({ data }) => data.uuuReport);

export const getIndividualReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: individualReport,
  }).then(({ data }) => data.individualReport);

export const getDividendsReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: dividendsReport,
  }).then(({ data }) => data.dividendsReport);

export const getSectorReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: sectorReport,
  }).then(({ data }) => data.sectorReport);

export const getQuarters = async () =>
  API.graphql({
    // variables: { request: data },
    query: quartersDropdown,
  }).then(({ data }) => data.quartersDropdown);

export const getIIRQuarters = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: iirQuartersDropdown,
  }).then(({ data }) => data.iirQuartersDropdown);

export const getSectors = async () =>
  API.graphql({
    // variables: { request: data },
    query: sectorsDropdown,
  }).then(({ data }) => data.sectorsDropdown);
export const getHeros = async () =>
  API.graphql({
    // variables: { request: data },
    query: heroDropdown,
    authMode: GRAPHQL_AUTH_MODE.API_KEY,
  }).then(({ data }) => data.heroDropdown);

export const getFilerNames = async (value) =>
  API.graphql({
    variables: { keyword: value },
    query: fundNamesDropdown,
  }).then(({ data }) => data.fundNamesDropdown);
export const getCountries = async () =>
  API.graphql({
    // variables: { request: data },
    query: countryDropdown,
  }).then(({ data }) => data.countryDropdown);

export const getInsiderTitles = async () =>
  API.graphql({
    // variables: { request: data },
    query: insiderTitlesDropdown,
  }).then(({ data }) => data.insiderTitlesDropdown);

export const getSeriesNames = async (value) =>
  API.graphql({
    variables: { keyword: value },
    query: seriesNamesDropdown,
  }).then(({ data }) => data.seriesNamesDropdown);

export const getMutualFundSymbols = async (value) =>
  API.graphql({
    variables: { keyword: value },
    query: mutualFundSymbolsDropdown,
  }).then(({ data }) => data.mutualFundSymbolsDropdown);

export const getMutualFundFundSymbols = async () =>
  API.graphql({
    // variables: { request: data },
    query: mutualFundFundSymbolsDropdown,
  }).then(({ data }) => data.mutualFundFundSymbolsDropdown);

export const getIndustries = async () =>
  API.graphql({
    // variables: { request: data },
    query: industriesDropdown,
  }).then(({ data }) => data.industriesDropdown);

export const getInsiderReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: insidersReport,
  }).then(({ data }) => data.insidersReport);
export const getInsiderSymbolSummaryReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: insidersSymbolSummaryReport,
  }).then(({ data }) => data.insidersSymbolSummaryReport);

export const getTrendingStockReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: trendingStocksReport,
  }).then(({ data }) => data.trendingStocksReport);

export const getTrendingStocksActivesReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: trendingStocksActivesReport,
  }).then(({ data }) => data.trendingStocksActivesReport);
export const getDayTrendSymbolData = (symbol) =>
  API.graphql({
    variables: { symbol: symbol },
    query: dayTrendBySymbol,
  }).then(({ data }) => data.dayTrendBySymbol);
export const getRecommendationSymbolData = (symbol) =>
  API.graphql({
    variables: { symbol: symbol },
    query: recommendationBySymbol,
  }).then(({ data }) => data.recommendationBySymbol);
export const getNewsSymbolData = (symbol) =>
  API.graphql({
    variables: { symbol: symbol },
    query: newsBySymbol,
  }).then(({ data }) => data.newsBySymbol);
export const getCompanyProfileSymbolData = (symbol) =>
  API.graphql({
    variables: { symbol: symbol },
    query: companyProfileBySymbol,
  }).then(({ data }) => data.companyProfileBySymbol);

export const getNoOfWeeksDropdown = async () =>
  API.graphql({
    query: noOfWeeksDropdown,
  }).then(({ data }) => data.noOfWeeksDropdown);

export const getNoOfWeeksForWeeklyYTDDropdown = async () =>
  API.graphql({
    query: noOfWeeksForWeeklyYTDDropdown,
  }).then(({ data }) => data.noOfWeeksForWeeklyYTDDropdown);

export const getNoOfDowdaysDropdown = async () =>
  API.graphql({
    query: noOfDowDaysDropdown,
  }).then(({ data }) => data.noOfDowDaysDropdown);

export const getDecreasedDowDailyTheoryReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: decreasedDowDailyTheoryReport,
  }).then(({ data }) => data.decreasedDowDailyTheoryReport);

export const getIncreasedDowDailyTheoryReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: increasedDowDailyTheoryReport,
  }).then(({ data }) => data.increasedDowDailyTheoryReport);

export const getNoOfDowDaysForPriceAndVolDropdown = async () =>
  API.graphql({
    query: noOfDowDaysForPriceAndVolDropdown,
  }).then(({ data }) => data.noOfDowDaysForPriceAndVolDropdown);

export const getIncreasedDowDailyPriceAndVolTheoryReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: increasedDowDailyPriceAndVolTheoryReport,
  }).then(({ data }) => data.increasedDowDailyPriceAndVolTheoryReport);

export const getDecreasedDowDailyPriceAndVolTheoryReport = async (data: {}) =>
  API.graphql({
    variables: { request: data },
    query: decreasedDowDailyPriceAndVolTheoryReport,
  }).then(({ data }) => data.decreasedDowDailyPriceAndVolTheoryReport);
