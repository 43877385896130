import React from 'react';

import { Menu, Switch } from 'antd';
import { NavLink } from 'react-router-dom';

import { isStudentUrl } from '../../utils';
import styles from './style.module.scss';
// const { SubMenu } = Menu;

const isStudent = isStudentUrl();

export const links1 = (isStudent: boolean) => {
  let links = [
    {
      name: 'Search',
      path: '/search',
      text: '/search',
      id: 'search',
      isNew: false,
    },
    {
      name: 'Reports',
      path: '/reports/filters=&isTop10=false&page=1&reportCode=TS&reportType=daily&searchText=&size=20',
      text: '/reports/:reportQueryData?',
      id: 'reports',
      isNew: false,
    },
    // {
    //   name: 'Earnings Calender',
    //   path: '/earningsCalender',
    //   text: '/earningsCalender',
    //   id: 'earningsCalender',
    // },
    // {
    //   name: 'Our Picks',
    //   path: '/our/picks/all',
    //   text: '/our/picks/:type',
    //   id: 'ourPicks',
    //   isNew: false,
    // },
  ];

  if (!isStudent) {
    links
      .push
      // {
      //   name: 'Our Picks',
      //   path: '/our/picks/all',
      //   text: '/our/picks/:type',
      //   id: 'ourPicks',
      //   isNew: false,
      // },
      // {
      //   name: 'Community',
      //   path: '/community',
      //   text: '/community',
      //   id: 'community',
      // },
      ();
  }
  return links;
};

export const sidebarLinks = (isStudent: boolean) => {
  let links = [
    {
      name: 'Portfolio',
      path: isStudent
        ? '/portfolio/Portfolio/virtual'
        : '/portfolio/Portfolio/real',
      text: isStudent
        ? '/portfolio/Portfolio/virtual'
        : '/portfolio/Portfolio/real',
      id: 'portfolio',
    },

    {
      name: 'Search',
      path: '/search',
      text: '/search',
      id: 'search',
    },
    {
      name: 'Reports',
      path: '/reports/filters=&isTop10=false&page=1&reportCode=TS&reportType=daily&searchText=&size=20',
      text: '/reports/:reportQueryData?',
      id: 'reports',
    },
    // {
    //   name: 'Our Picks',
    //   path: '/our/picks/all',
    //   text: '/our/picks/:type',
    //   id: 'ourPicks',
    // },
    // {
    //   name: 'Community',
    //   path: '/community',
    //   text: '/community',
    //   id: 'community',
    // },
    {
      name: 'Alerts',
      path: '/alerts',
      text: '/alerts',
      id: 'alerts',
    },
    {
      name: `ThinkSabien's Activity`,
      path: '/trending/symbol/daily',
      text: '/trending/symbol/daily',
      id: 'trending',
    },
    {
      name: `TS-Youth Heroes`,
      path: '/Gamification',
      text: '/Gamification',
      id: 'Gamification',
    },
    {
      name: `Founder's Story`,
      path: '/founder-story',
      text: '/founder-story',
      id: 'founder-story',
    },
    {
      name: `TS-University`,
      path: '/university',
      text: '/university',
      id: 'university',
    },
    {
      name: `Donate`,
      path: '/donate',
      text: '/donate',
      id: 'donate',
    },
    {
      name: `FAQ`,
      path: '/faq',
      text: '/faq',
      id: 'faq',
    },
    {
      name: `Contact Us`,
      path: '/contact-us',
      text: '/contact-us',
      id: 'contact-us',
    },
    {
      name: `Profile & Settings`,
      path: '/setting',
      text: '/setting',
      id: 'setting',
    },
  ];

  if (!isStudent) {
    links.splice(3, 0, {
      name: 'Our Picks',
      path: '/our/picks/all',
      text: '/our/picks/:type',
      id: 'ourPicks',
    });
    // links.splice(4, 0, {
    //   name: 'Community',
    //   path: '/community',
    //   text: '/community',
    //   id: 'community',
    // });
  }
  return links;
};

// export const links2 = [
//   {
//     name: 'Reports',
//     path:
//       '/reports/filters=&isTop10=false&page=1&reportCode=AID&reportType=daily&searchText=&size=20',
//     text: '/reports/:reportQueryData?',
//     id: 'reports',
//   },
// ];

export const thinksabioPageDropDownMenu = (signOut) => (
  <Menu className={styles.dashboardMenuListBox}>
    {/*<Menu.Item>*/}
    {/*  <NavLink to="/earningsCalender" activeClassName={styles.navActiveClass}>*/}
    {/*    <span>{`Earnings Calender`}</span>*/}
    {/*  </NavLink>*/}
    {/*</Menu.Item>*/}
    <Menu.Item>
      <NavLink to="/our/picks/:type/" activeClassName={styles.navActiveClass}>
        <span>{`TS Picks`}</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/community" activeClassName={styles.navActiveClass}>
        <span>{`Community`}</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/university" activeClassName={styles.navActiveClass}>
        <span>{`TS-University`}</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/tools" activeClassName={styles.navActiveClass}>
        <span>{`Tools`}</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/donate" activeClassName={styles.navActiveClass}>
        <span>{`Donate`}</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/founder-story" activeClassName={styles.navActiveClass}>
        <span>{`Founder's Story`}</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/faq" activeClassName={styles.navActiveClass}>
        <span>{`FAQ`}</span>
      </NavLink>
    </Menu.Item>

    <Menu.Item>
      <NavLink to="/contact-us" activeClassName={styles.navActiveClass}>
        <span>Contact Us</span>
      </NavLink>
    </Menu.Item>
  </Menu>
);

export const userActionDropDown = (
  signOut,
  onThemeChange,
  theme,
  showSetting,
) => (
  <Menu className={styles.dashboardMenuListBox}>
    {showSetting && (
      <Menu.Item>
        <NavLink to="/setting" activeClassName={styles.navActiveClass}>
          <span>{`Profile & Settings`}</span>
        </NavLink>
      </Menu.Item>
    )}

    <Menu.Item>
      <span>{`Dark Mode`}</span>
      <Switch defaultChecked={theme === 'dark'} onChange={onThemeChange} />
    </Menu.Item>

    <Menu.Item onClick={signOut}>
      <span>Sign Out</span>
    </Menu.Item>
  </Menu>
);

export const myUniverseDropDownMenu = (
  <Menu className={styles.dashboardMenuListBox}>
    {isStudent ? (
      <>
        <Menu.Item>
          <NavLink
            to="/GamificationByState"
            activeClassName={styles.navActiveClass}
          >
            <span>{`TS-Youth Heroes`}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item>
          <NavLink
            to="/portfolio/Portfolio/virtual"
            activeClassName={styles.navActiveClass}
          >
            <span>Portfolio</span>
          </NavLink>
        </Menu.Item>
      </>
    ) : (
      <Menu.Item>
        <NavLink
          to="/portfolio/Portfolio/real"
          activeClassName={styles.navActiveClass}
        >
          <span>Portfolio</span>
        </NavLink>
      </Menu.Item>
    )}
    <Menu.Item>
      <NavLink to="/alerts" activeClassName={styles.navActiveClass}>
        <span>Alerts</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink
        to="/trending/symbol/daily"
        activeClassName={styles.navActiveClass}
      >
        <span>{`ThinkSabien's Activity`}</span>
      </NavLink>
    </Menu.Item>
  </Menu>
);
export const homeSearchDropDownMenu = (
  <Menu className={styles.dashboardMenuListBox}>
    <Menu.Item>
      <NavLink
        to="/search/report/subtype=thinksabio_diamonds&type=thinksabio_diamonds"
        activeClassName={styles.navActiveClass}
      >
        <span>TS Diamonds</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink
        to="/search/report/subtype=criteria_met&type=mark_minervini_strategy"
        activeClassName={styles.navActiveClass}
      >
        <span>{`Mark Minervini Strategy`}</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink
        to="/search/report/subtype=c52wkhl&type=symbol"
        activeClassName={styles.navActiveClass}
      >
        <span>{`52-week-low reversal`}</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink
        to="/reports/filters=&isTop10=false&page=1&quarter=2024_Q3&reportCode=AIU&reportType=quarterly&searchText=&selectedDate=0&size=20"
        activeClassName={styles.navActiveClass}
      >
        <span>{`Ultimate report`}</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink
        to="/search/report/subtype=ts_weekly_report&type=symbol"
        activeClassName={styles.navActiveClass}
      >
        <span>{`Top weekly gems`}</span>
      </NavLink>
    </Menu.Item>
  </Menu>
);
export const myUniverseDropDownMenuForPaid = (
  <Menu className={styles.dashboardMenuListBox}>
    {isStudent ? (
      <Menu.Item>
        <NavLink
          to="/portfolio/Portfolio/virtual"
          activeClassName={styles.navActiveClass}
        >
          <span>Portfolio</span>
        </NavLink>
      </Menu.Item>
    ) : (
      <Menu.Item>
        <NavLink
          to="/portfolio/Portfolio/real"
          activeClassName={styles.navActiveClass}
        >
          <span>Portfolio</span>
        </NavLink>
      </Menu.Item>
    )}

    <Menu.Item>
      <NavLink to="/alerts" activeClassName={styles.navActiveClass}>
        <span>Alerts</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink
        to="/trending/symbol/daily"
        activeClassName={styles.navActiveClass}
      >
        <span>{`ThinkSabien's Activity`}</span>
      </NavLink>
    </Menu.Item>
  </Menu>
);
