import React, { useState, useEffect } from 'react';

import { get } from 'lodash';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';

import {
  getSavedSearchName,
  getNoOfWeeksForWeeklyYTDDropdown,
} from '../../graphql';
import { setWeeklyYTDNoOfWeeks } from '../../redux/reducers/report';
import {
  noOfQtrList,
  errorHandler,
  getLast5Dates,
  getTimeRange,
} from '../../utils';
import { setLocationTitle } from '../../utils/LocationHelper';
import CommonSearchPage from './CommonSearchPage';
import OptionBySymbol from './OptionBySymbol';
import { getReportInfo } from './SearchReport.constants';
import { getGraphQlQueryByType } from './SearchReport.helper';
import { SearchReportContext } from './SearchReportContext';
import SearchSymbolPage from './SearchSymbolPage';
import SectorOverview from './SectorOverview';
import styles from './style.module.scss';
import SymbolComparePage from './SymbolComparePage';
import WeeklyGainsView from './weeklyGainsPage';

interface Props extends RouteComponentProps<any> {}

const SearchReport: React.FC<Props> = ({ history }) => {
  const { searchQueryData } = useParams();
  const dispatch = useDispatch();
  const queryData = queryString.parse(searchQueryData);
  const reportType = get(queryData, 'type', '').toUpperCase();
  const reportSubType = get(queryData, 'subtype', '').toUpperCase();
  const {
    quarters,
    noOfWeeksList,
    noOfDaysList,
    noOfDaysForVolumeList,
    iirQuarters,
    noOfWeeklyYTDWeeksList,
  } = useSelector(
    ({ report }) => ({
      quarters: get(report, 'quarters', []),
      iirQuarters: get(report, `iirQuarters.${queryData.institutions}`, []),
      noOfWeeksList: get(report, 'noOfWeeks', []),
      noOfWeeklyYTDWeeksList: get(report, 'noOfWeeklYTDWeeks', []),
      noOfDaysList: get(report, 'noOfDays', []),
      noOfDaysForVolumeList: get(report, 'noOfDaysForVolume', []),
    }),
    shallowEqual,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fetchingName, setFetchingName] = useState<boolean>(true);
  const [searchData, setSearchData] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const reportCode =
    reportSubType === 'INSIDER_DAILY_REPORT'
      ? 'DIL'
      : reportSubType === 'DAILY_REPORT'
      ? 'SDY'
      : '';
  const [selectedDateIndex, setSelectedDateIndex] = useState<number>(
    parseInt(Math.max(...getLast5Dates(reportCode))),
  );
  // const [selectedNoOfQShares, setSelectedNoOfQShares] = useState<number>(2);
  // const [selectedNoOfQCount, setSelectedNoOfQCount] = useState<number>(2);
  const [selectedNoOfQtrs, setSelectedNoOfQtrs] = useState<number>(2);
  const [noOfWeeks, setNoOFWeeks] = useState<number>(
    reportSubType === 'TS_WEEKLY_REPORT' ? 2 : 4,
  );
  const [noOfDays, setNoOfDays] = useState<number>(
    reportSubType === 'CRITERIA_MET' ? null : 2,
  );
  const [currentSearchCriteria, setCurrentSearchCriteria] = useState('');
  const [reportTitle, setReportTitle] = useState<string>('');
  const [timeframe, setTimeframe] = useState<string>('1D');
  const [sortDetail, setSortDetail] = useState([]);
  const [searchText, setSearchText] = useState<string>('');
  const [tableColumns, setTableColumns] = useState([]);
  const [uniqueVals, setUniqueVals] = useState([]);
  const [error, setError] = useState('');
  const [isIRR, setIsIRR] = useState(false);
  const [isIAR, setIsIAR] = useState(false);
  const [selectedSector, setSelectedSector] = useState('');
  const [fetchData, setFetchData] = useState<Date>(new Date());
  const [trendingStockType, setTrendingStockType] = useState<string>('GAINERS');
  const [topGainersType, setTopGainersType] = useState<string>('GAINERS');
  const quartersOptions =
    reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT'
      ? iirQuarters
      : reportSubType === 'INSTITUTION_ALLOCATION_REPORT' &&
        quarters[9].value === '2020_Q4'
      ? quarters.slice(0, 9)
      : quarters;
  const [selectedQuarter, setSelectedQuarters] = useState(
    get(
      reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT'
        ? iirQuarters[0]
        : quarters[0],
      'value',
      '',
    ),
  );
  const [selectedWeeks, setSelectedWeeks] = useState('4');
  const getFilterValue = () => {
    const {
      minPrice,
      maxPrice,
      minCount,
      maxCount,
      minInstShares,
      maxInstShares,
      minAproxTxnValue,
      minAproxInvValue,
      maxAproxTxnValue,
      week,
      minStartPrice,
      maxStartPrice,
      minP52WkLow,
      maxP52WkLow,
      minP52WkHigh,
      maxP52WkHigh,
      minEndPrice,
      maxEndPrice,
      sector,
      symbols,
      fundSymbols,
      filerName,
      seriesName,
      minYtd,
      maxYtd,
      minRank,
      maxRank,
      minPossibleGrowth,
      maxPossibleGrowth,
      maxOneYrGrowth,
      minOneYrGrowth,
      maxFiveYrGrowth,
      minFiveYrGrowth,
      maxInstCount,
      minInstCount,
      country,
      minPrevQInstCount,
      maxPrevQInstCount,
      minAum,
      maxAum,
      minPosReturnsPerc,
      maxPosReturnsPerc,
      minNegReturnsPerc,
      maxNegReturnsPerc,
      minNegReturnsStockCount,
      maxNegReturnsStockCount,
      minPosReturnsStockCount,
      maxPosReturnsStockCount,
      minTotalCount,
      maxTotalCount,
      minPrevAum,
      maxPrevAum,
      hedgeCount,
      minMarketCap,
      maxMarketCap,
      multiStatus,
      maxInstCountChange,
      minInstCountChange,
      minInstSharesChange,
      maxInstSharesChange,
      multiSector,
      excludeMultiSector,
      multiIndustry,
      startDate,
      fromDate,
      endDate,
      criterion,
      // institutions,
    } = queryData;
    const dateRange = getTimeRange(timeframe, reportSubType === 'TOP_GAINERS');
    return {
      minPrice: minPrice,
      maxPrice: maxPrice,
      minInstShares: minInstShares,
      maxInstShares: maxInstShares,
      minAproxTxnValue: minAproxTxnValue,
      minAproxInvValue: minAproxInvValue,
      maxAproxTxnValue: maxAproxTxnValue,
      sector: sector,
      symbols: symbols,
      fundSymbols: fundSymbols,
      minStartPrice: minStartPrice,
      minP52WkLow: minP52WkLow,
      maxP52WkLow: maxP52WkLow,
      minP52WkHigh: minP52WkHigh,
      maxP52WkHigh: maxP52WkHigh,
      week: week,
      maxStartPrice: maxStartPrice,
      minEndPrice: minEndPrice,
      maxEndPrice: maxEndPrice,
      minPrevQInstCount: minPrevQInstCount,
      country: country,
      maxPrevQInstCount: maxPrevQInstCount,
      minAum: minAum,
      maxAum: maxAum,
      minPosReturnsPerc: minPosReturnsPerc,
      maxPosReturnsPerc: maxPosReturnsPerc,
      minNegReturnsPerc: minNegReturnsPerc,
      maxNegReturnsPerc: maxNegReturnsPerc,
      minNegReturnsStockCount: minNegReturnsStockCount,
      maxNegReturnsStockCount: maxNegReturnsStockCount,
      minPosReturnsStockCount: minPosReturnsStockCount,
      maxPosReturnsStockCount: maxPosReturnsStockCount,
      minTotalCount: minTotalCount,
      maxTotalCount: maxTotalCount,
      minCount: reportSubType === 'C52WKHL' ? (minCount ? minCount : 4) : '',
      // maxCount: maxCount ? maxCount : 1,
      maxCount: reportSubType === 'C52WKHL' ? (maxCount ? maxCount : 4) : '',
      minPrevAum: minPrevAum,
      maxPrevAum: maxPrevAum,
      hedgeCount: hedgeCount,
      minMarketCap: minMarketCap,
      maxMarketCap: maxMarketCap,
      ytd: { min: minYtd, max: maxYtd },
      oneYrGrowth: { min: minOneYrGrowth, max: maxOneYrGrowth },
      fiveYrGrowth: { min: minFiveYrGrowth, max: maxFiveYrGrowth },
      instCount: {
        min: minInstCount,
        max: maxInstCount,
      },
      instCountChange: {
        min: minInstCountChange,
        max: maxInstCountChange,
      },
      instSharesChange: {
        min: minInstSharesChange,
        max: maxInstSharesChange,
      },
      multiSector: multiSector ? multiSector : [],
      maxPossibleGrowth: maxPossibleGrowth,
      minPossibleGrowth: minPossibleGrowth,
      minRank: minRank,
      maxRank: maxRank,
      excludeMultiSector: excludeMultiSector ? excludeMultiSector : [],
      multiIndustry: multiIndustry ? multiIndustry : [],
      multiStatus: multiStatus ? multiStatus : [],
      filerName: filerName,
      seriesName: seriesName,
      noOfWeeks: null,
      noOfDays: null,
      endDate:
        timeframe &&
        (reportSubType === 'SECTOR_TRENDING' || reportSubType === 'TOP_GAINERS')
          ? dateRange.end
          : endDate,
      startDate:
        timeframe &&
        (reportSubType === 'SECTOR_TRENDING' || reportSubType === 'TOP_GAINERS')
          ? dateRange.start
          : startDate,
      fromDate: fromDate,
      criterion: criterion ? criterion.toUpperCase() : null,
      // institutions: institutions ? institutions : [],
    };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const { lastUpdatedTime } = searchData;
      var tmz = 'America/New_York';
      var fmt = 'HH:mm:ss';

      var start = moment.tz('04:10:00', fmt, tmz);
      var end = moment.tz('20:00:00', fmt, tmz);
      const isBetween04And2000 = moment
        .tz(moment.now(), 'America/New_York')
        .isBetween(start, end);

      const diff = moment()
        .tz('America/New_York')
        .diff(moment(lastUpdatedTime).tz('America/New_York'), 'seconds');
      console.log('Checking Diff', diff, isBetween04And2000);
      if (
        reportSubType === 'TOP_GAINERS' &&
        diff >= 360 &&
        isBetween04And2000
      ) {
        setFetchData(new Date());
      } else if (
        (reportSubType === 'TRENDING_STOCK' ||
          reportSubType === 'TOP_GAINERS') &&
        !isBetween04And2000
      ) {
        setError('inActive');
      }
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [reportSubType, searchData]);

  const [filterOptions, setFilterOptions] = useState(getFilterValue());
  const noOfQuartersForShares = noOfQtrList;
  const noOfQuartersForCounts = noOfQtrList;
  const findColumnVisibility = (col) => {
    let oldCol = tableColumns.find((oldCol) => oldCol.key === col);
    if (oldCol) {
      return oldCol.visible;
    }
    return true;
  };

  useEffect(() => {
    if (reportSubType === 'WEEKLY_YTD') {
      const promises = [getNoOfWeeksForWeeklyYTDDropdown()];
      Promise.all(promises)
        .then((res) => {
          dispatch(setWeeklyYTDNoOfWeeks(res[0]));
        })
        .catch(errorHandler)
        .finally(() => {});
    }
  }, [dispatch, reportSubType]);

  const formatColumns = (columns: {}) => {
    if (
      reportSubType !== 'INDIVIDUAL_STATISTICAL_REPORT' &&
      reportSubType !== 'COMPARE_SYMBOLS'
    ) {
      const formattedColumns = (columns || []).map((col, index) => ({
        key: col.key,
        name: col.key,
        value: col.value,
        dataIndex: col.key,
        type: col.type,
        visible:
          col.key === 'cnst_count' || col.key === 'end_date'
            ? false
            : findColumnVisibility(col),
        index: col.key === 'rank' ? 0 : col.key === 'symbol' ? 1 : index + 2,
        sort: true,
      }));
      return formattedColumns;
    }
    return columns;
  };

  useEffect(() => {
    setSearchText('');
    if (reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT') {
      setSelectedQuarters(get(iirQuarters[0], 'value', ''));
      setFetchData(new Date());
    }
    if (reportSubType === 'INSTITUTION_PERFORMANCE_REPORT') {
      setSelectedQuarters(quarters[0].value);
      // setFetchData(new Date());
    }
    if (reportSubType === 'INSTITUTION_ALLOCATION_REPORT') {
      setSelectedQuarters(quarters[0].value);
      // setFetchData(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportSubType]);

  useEffect(() => {
    setError('');
    setLocationTitle(
      window.location,
      reportSubType === 'TRENDING_STOCK'
        ? trendingStockType
        : reportSubType === 'TOP_GAINERS'
        ? topGainersType
        : null,
    );
    const { query, queryData } = getGraphQlQueryByType(
      reportType,
      reportSubType,
      queryString.parse(searchQueryData),
      pageNo,
      pageSize,
      selectedQuarter,
      selectedNoOfQtrs,
      noOfWeeks,
      filterOptions,
      sortDetail,
      searchText,
      selectedDateIndex,
      trendingStockType,
      topGainersType,
      noOfDays,
      noOfWeeklyYTDWeeksList,
    );
    if (queryData && queryData.filter && queryData.filters.type) {
      delete queryData.filters.type;
    }
    const promises = [
      query,
      getSavedSearchName(
        JSON.stringify({
          type: reportType,
          subType: reportSubType,
          request: queryData,
        }),
      ),
    ];
    setIsLoading(true);
    Promise.all(promises)
      .then((res) => {
        setIsLoading(true);
        const { columns = [] } = res[0];
        setSearchData(res[0]);
        setUniqueVals(res[0].cnstDays);
        const { lastUpdatedTime } = searchData;
        var tmz = 'America/New_York';
        var fmt = 'HH:mm:ss';

        var start = moment.tz('04:10:00', fmt, tmz);
        var end = moment.tz('20:00:00', fmt, tmz);
        const isBetween04And2000 = moment
          .tz(moment.now(), 'America/New_York')
          .isBetween(start, end);

        const diff = moment()
          .tz('America/New_York')
          .diff(moment(lastUpdatedTime).tz('America/New_York'), 'seconds');
        console.log('Checking Diff', diff, isBetween04And2000);
        if (
          (reportSubType === 'TRENDING_STOCK' ||
            reportSubType === 'TOP_GAINERS') &&
          !isBetween04And2000
        ) {
          setError('inActive');
        }
        if (
          reportType === 'MARK_MINERVINI_STRATEGY' ||
          reportType === 'THINKSABIO_DIAMONDS'
        ) {
          const wlEntry = {
            key: 'watchlist',
            value: 'Add To WatchList',
            type: null,
          };
          columns.splice(0, 0, wlEntry);
        }
        setTableColumns(formatColumns(columns));
        if (res[1] && res[1].reportName) {
          setReportTitle(res[1].reportName);
        }
        setCurrentSearchCriteria(queryData);
        if (reportSubType === 'SECTOR_TRENDING' && res[0] && res[0].data) {
          const records = res[0].data
            .reduce((pv, cv, index) => {
              const obj = {};
              cv.forEach((d) => {
                obj[d.key] = isNaN(d.value) ? d.value : +d.value; // d.value;
              });
              pv.push({
                ...obj,
                index: obj.symbol === 'SPY' ? 0 : index + 1,
              });
              return pv;
            }, [])
            .sort((a, b) => a.index - b.index);
          setSelectedSector(records[1].sector);
        }
      })
      .catch((err) => {
        errorHandler(err);
        setSearchData({});
        if (
          err.errors &&
          !err.errors[0].message?.includes('SearchCountsService')
        ) {
          setError('Error While Fetching Data. Please try again');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNo,
    pageSize,
    reportSubType,
    reportType,
    searchQueryData,
    selectedNoOfQtrs,
    noOfWeeks,
    noOfDays,
    fetchData,
    // filterOptions,
    // sortDetail,
    searchText,
    selectedDateIndex,
    trendingStockType,
    topGainersType,
  ]);

  const fetchReportName = () => {
    setFetchingName(true);
    const promises = [
      getSavedSearchName(
        JSON.stringify({
          type: reportType,
          subType: reportSubType,
          request: currentSearchCriteria,
        }),
      ),
    ];
    Promise.all(promises)
      .then((res) => {
        setReportTitle(res[0].reportName);
      })
      .catch(errorHandler)
      .finally(() => {
        setFetchingName(false);
      });
  };

  const changePageNo = (newPageNo: number) => {
    setPageNo(newPageNo);
  };
  const changePageSize = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const handleSort = (sortBy: string, sortOrder: string, multi: boolean) => {
    if (multi) {
      let newSort = sortDetail;
      if (sortOrder) {
        newSort = sortDetail.filter((s) => s.sortBy !== sortBy);
        newSort.push({
          sortBy: sortBy,
          sortOrder: sortOrder,
        });
      } else {
        newSort = sortDetail.filter((s) => s.sortBy !== sortBy);
      }
      setSortDetail([...newSort]);
    } else {
      if (sortOrder) {
        setSortDetail([
          {
            sortBy: sortBy,
            sortOrder: sortOrder,
          },
        ]);
      } else {
        setSortDetail([]);
      }
    }
    setFetchData(new Date());
  };

  const onQuarterChange = (quarter: string) => {
    setSelectedQuarters(quarter);
    setFetchData(new Date());
  };
  const onWeeksChange = (duration: string) => {
    setSelectedWeeks(duration);
    setFilterOptions({
      ...filterOptions,
      maxCount: duration,
      minCount: duration,
    });
    setFetchData(new Date());
  };

  // const onNoQtrsChange = (quarter) => {
  //   selectedNoOfQtrs(quarter);
  // };

  // const onNoQCountChange = (quarter) => {
  //   setSelectedNoOfQCount(quarter);
  // };

  const onNoOfWeekChange = (value: number) => {
    setNoOFWeeks(value);
    setFilterOptions({
      ...filterOptions,
      noOfWeeks: value,
    });
  };

  const onNoOfWeeklyTYDWeekChange = (value: number) => {
    setFilterOptions({
      ...filterOptions,
      noOfWeeklyYTDWeeks: value,
    });
    setFetchData(new Date());
  };

  const onNoOfDaysChange = (value: number) => {
    setNoOfDays(value);
    setFilterOptions({
      ...filterOptions,
      noOfDays: value,
    });
  };

  const onTypeChange = (val: string) => {
    setFilterOptions({
      ...filterOptions,
      type: val,
    });
    setFetchData(new Date());
  };
  const onDurationChange = (val: string) => {
    let startingDate = moment(new Date())
      .subtract(1, 'year')
      .format('YYYY-MM-DD');
    if (val === '1Week') {
      startingDate = moment(new Date())
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
    }
    if (val === '2Weeks') {
      startingDate = moment(new Date())
        .subtract(2, 'week')
        .format('YYYY-MM-DD');
    }
    if (val === '1Month') {
      startingDate = moment(new Date())
        .subtract(1, 'month')
        .format('YYYY-MM-DD');
    }
    if (val === '2Months') {
      startingDate = moment(new Date())
        .subtract(2, 'month')
        .format('YYYY-MM-DD');
    }
    if (val === '3Months') {
      startingDate = moment(new Date())
        .subtract(3, 'month')
        .format('YYYY-MM-DD');
    }
    if (val === '6Months') {
      startingDate = moment(new Date())
        .subtract(6, 'month')
        .format('YYYY-MM-DD');
    }
    setFilterOptions({
      ...filterOptions,
      startDate: startingDate,
    });
    setFetchData(new Date());
  };
  const onCategoryChange = (val: string) => {
    setFilterOptions({
      ...filterOptions,
      category: val,
    });
    setFetchData(new Date());
  };
  const onStatusTxnChange = (val: string) => {
    setFilterOptions({
      ...filterOptions,
      status: val,
    });
    setFetchData(new Date());
  };
  const onNasdaqChange = (e) => {
    setFilterOptions({
      ...filterOptions,
      isNasdaq: e.target.checked,
    });
    setFetchData(new Date());
  };
  const onDOWJONESChange = (e) => {
    setFilterOptions({
      ...filterOptions,
      isDow: e.target.checked,
    });
    setFetchData(new Date());
  };
  const onSoryByVolChange = (e) => {
    setFilterOptions({
      ...filterOptions,
      sortByVol: e.target.checked,
    });
    setFetchData(new Date());
  };

  const onSPChange = (e) => {
    setFilterOptions({
      ...filterOptions,
      isSP: e.target.checked,
    });
    setFetchData(new Date());
  };
  const onFilter = (newFilters, sort, tf, reset) => {
    if (sort && Array.isArray(sort)) {
      setSortDetail(sort);
    }
    if (newFilters.maxCount && reportSubType === 'C52WKHL') {
      setSelectedWeeks(newFilters.maxCount.toString());
      setSelectedWeeks(newFilters.minCount.toString());
    }
    if (reportSubType === 'WEEKLY_GAINS_REPORT') {
      const filters = {
        symbols: newFilters.symbols,
        type: 'INTERNAL_REPORTS',
        subtype: 'WEEKLY_GAINS_REPORT',
      };
      // eslint-disable-next-line no-restricted-globals
      history.push(`/search/report/` + queryString.stringify(filters));
    }

    setFilterOptions({
      type: filterOptions.type,
      category: filterOptions.category,
      status: filterOptions.status,
      isSP: reset ? false : filterOptions.isSP,
      isNasdaq: reset ? false : filterOptions.isNasdaq,
      isDow: reset ? false : filterOptions.isDow,
      fundSymbols: filterOptions.fundSymbols,
      ...newFilters,
    });
    if (tf) {
      setTimeframe(tf);
    } else {
      setTimeframe('');
    }
    setFetchData(new Date());
  };

  const onColumnFilter = (filteredColumns: {}) => {
    setTableColumns(filteredColumns);
  };

  const changeSearchText = (value: string) => {
    setPageNo(1);
    setSearchText(value);
    // setFilterOptions({});
  };
  const onDateSelect = (index: number) => {
    setSelectedDateIndex(index);
    changePageNo(1);
  };
  const handleISRTOIIR = async () => {
    setSearchData({});
    setSortDetail([]);
    setSelectedQuarters(quartersOptions[0].value);
    setSelectedNoOfQtrs(2);
    setPageNo(1);
    setPageSize(20);
    setTableColumns([]);
    setIsLoading(true);
    setError('');
    if (reportSubType === 'INSTITUTION_PERFORMANCE_REPORT') {
      setIsIRR(true);
    }
    if (reportSubType === 'INSTITUTION_ALLOCATION_REPORT') {
      setIsIAR(true);
    }
  };
  const handleISRTOStatusReport = async () => {
    setSearchData({});
    setSortDetail([]);
    setPageNo(1);
    setPageSize(20);
    setTableColumns([]);
    setIsLoading(true);
    setError('');
  };
  return (
    <SearchReportContext.Provider
      value={{
        searchData: searchData ? searchData : {},
        isLoading: isLoading,
        reportInfo: getReportInfo(
          reportSubType || reportType,
          queryData,
          reportType,
          get(searchData, 'reportType', ''),
        ),
        currentSearchCriteria,
        reportType,
        reportSubType,
        reportTitle,
        fetchReportName,
        fetchingName,
        // eslint-disable-next-line no-restricted-globals
        history,
        handleISRTOIIR,
        setIsLoading,
        setFilterOptions,
        setSearchData,
        setTableColumns,
        queryData,
        onFilter,
        filterOptions,
        sortDetail,
        tableColumns,
        error,
        timeframe,
        selectedSector,
        setSelectedSector,
        changePageNo,
        changePageSize,
        pageSize,
        pageNo,
        onSort: handleSort,
        searchText,
        quartersOptions,
        onQuarterChange,
        onWeeksChange,
        selectedQuarter,
        selectedWeeks,
        noOfQuartersForShares,
        noOfQuartersForCounts,
        onNoQtrsChange: setSelectedNoOfQtrs,
        selectedNoOfQtrs,
        onNoOfWeekChange,
        onNoOfWeeklyTYDWeekChange,
        onNoOfDaysChange,
        onTypeChange,
        onDurationChange,
        onStatusTxnChange,
        onCategoryChange,
        onSPChange,
        onNasdaqChange,
        onDOWJONESChange,
        onSoryByVolChange,
        noOfWeeks,
        noOfDays,
        onColumnFilter,
        changeSearchText,
        setSortDetail,
        noOfWeeksList,
        noOfWeeklyYTDWeeksList,
        noOfDaysList,
        selectedDateIndex,
        onDateSelect,
        setTopGainersType,
        topGainersType,
        trendingStockType,
        setTrendingStockType,
        noOfDaysForVolumeList,
        isIRR,
        isIAR,
        setIsIAR,
        handleISRTOStatusReport,
        uniqueVals,
      }}
    >
      <div
        className={styles.SearchReportBox}
        style={{ paddingTop: 100 }}
        id="SEARCH"
      >
        {reportSubType === 'INDIVIDUAL_STATISTICAL_REPORT' && (
          <SearchSymbolPage />
        )}
        {reportSubType === 'COMPARE_SYMBOLS' && <SymbolComparePage />}
        {reportSubType === 'OPTION_BY_SYMBOL' && <OptionBySymbol />}
        {reportSubType === 'SECTOR_TRENDING' && <SectorOverview />}
        {reportSubType === 'WEEKLY_GAINS_REPORT' && <WeeklyGainsView />}
        {[
          'VERIFY_SYMBOLS_VS_INSTITUTIONS',
          'MOVING_AVERAGES',
          'DOW_THEORY_UPTREND',
          'TS_WEEKLY_REPORT',
          'DOW_THEORY_DOWNTREND',
          'DOW_DAILY_PRICE_UPTREND',
          'DOW_DAILY_PRICE_DOWNTREND',
          'DOW_DAILY_PRICE_VOLUME_DOWNTREND',
          'DOW_DAILY_PRICE_VOLUME_UPTREND',
          'TELEGRAM_OPTIONS',
          'WEEKLY_YTD',
          'TRENDING_STOCK',
          'ALL_SYMBOLS',
          'KEYWORD_BASED_COMPANY',
          'C52WKHL',
          'INDIVIDUAL_INSTITUTION_REPORT',
          'INDIVIDUAL_STATUS_REPORT',
          'INSTITUTION_PERFORMANCE_REPORT',
          'INSTITUTION_ALLOCATION_REPORT',
          'COMPARE_INSTITUTIONS',
          'VERIFY_INSTITUTIONS_VS_SYMBOLS',
          'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS',
          'INCREASED',
          'NO_CHANGE',
          'DECREASED',
          'NEWLY_BOUGHT',
          'SOLD_OFF',
          'ALL_INSTITUTIONS',
          'TOP_10_HEDGE',
          'ALLOCATIONS',
          'DAILY_REPORT',
          'DAILY_REPORT_MUTUAL_FUNDS',
          'SYMBOL_SUMMARY',
          'SYMBOL_SUMMARY_MUTUAL_FUNDS',
          'BY_FUND_NAME',
          'BY_FUND_SYMBOL',
          'INSIDER_DAILY_REPORT',
          'INSIDER_ALL_CUMULATIVE_REPORT',
          'TOP_GAINERS',
          'INSIDER_DETAIL_SUMMARY_REPORT',
          'INSIDER_SYMBOL_SUMMARY',
          'OPTION_ALL_INSTITUTION',
          'OPTION_BY_INSTITUTION',
          'DETAIL_SUMMARY_REPORT',
          'BY_APPROX_TXN_VAL',
          'TOP_TWENTY_FIVE_SYMBOLS',
          'PRICE_HISTORY',
          'DOW_HIGH_LEVEL_REPORT',
          'DOW_DOWN_TREND_REPORT',
        ].includes(reportSubType) && <CommonSearchPage />}
        {[
          'PRICE',
          'BUILD_YOUR_OWN',
          'THINKSABIO_DIAMONDS',
          'MARK_MINERVINI_STRATEGY',
        ].includes(reportType) && <CommonSearchPage />}
      </div>
    </SearchReportContext.Provider>
  );
};

export default SearchReport;
