import React from 'react';

import { Checkbox, Select } from 'antd';
import { get } from 'lodash';
function ADMAFilter({
  className,
  filterOptions,
  onIsLastSaleGreaterChange,
  onIsLast5DaysGreaterChange,
  dmaType,
  onDmaTypeChange,
}) {
  return (
    <div className={className}>
      <div style={{ marginRight: '20px' }}>
        <label style={{ color: 'var(--whitecolor)' }} htmlFor="dmaType">
          Select DMA Type:
        </label>
        <Select
          id="dropdown"
          value={dmaType}
          onChange={onDmaTypeChange}
          style={{ width: 70 }}
        >
          <option value={1}>1</option>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={150}>150</option>
          <option value={200}>200</option>
        </Select>
      </div>
      <Checkbox
        checked={get(filterOptions, 'isLastSaleGreater', false)}
        onChange={onIsLastSaleGreaterChange}
      >
        {`Current Price > ${dmaType} DMA`}
      </Checkbox>
      <Checkbox
        checked={get(filterOptions, 'isLast5DaysGreater', false)}
        onChange={onIsLast5DaysGreaterChange}
      >
        {`Last 5 Days Price > ${dmaType} DMA`}
      </Checkbox>
    </div>
  );
}

export default ADMAFilter;
