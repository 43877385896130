import React, { useState, useEffect } from 'react';

import { Auth } from 'aws-amplify';
import { get } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';

import { isPromoBoolean } from '../../graphql';
import { errorHandler, isStudentUrl } from '../../utils';
import SignUpForm from './SignUpForm';

interface Props extends RouteComponentProps<any> {}

const SignUp: React.FC<Props> = ({ match, history }) => {
  const username = get(match, 'params.username', null);
  const code = get(match, 'params.code', null);
  const [isSignUp] = useState<boolean>(!username);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isUserVerified, setIsUserVerified] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [signUpClick, setSignUpClicked] = useState<boolean>(false);
  const [signUpDetails, setSignUpDetails] = useState<any>({});
  const [accessUrl, setAccessUrl] = useState<string>('');
  const [showVerifyMessage, setVerify] = useState<boolean>(false);
  const [tncAccepted, setTncAccepted] = useState<boolean>(true);
  const isStudent = isStudentUrl();
  const url = new URL(window.location.href);
  const referralUserId = url.searchParams.get('referralUserId');
  console.log(referralUserId);

  useEffect(() => {
    async function verifyUser() {
      setLoading(true);
      Auth.confirmSignUp(username, code, {})
        .then((data) => {
          setIsUserVerified(true);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setError(
            error.message === 'Username/client id combination not found.'
              ? 'No User found.'
              : error.message,
          );
        });
    }
    if (username && code) {
      verifyUser();
    }
  }, [username, code]);
  const facebookSignIn = () => {
    Auth.federatedSignIn({ provider: 'Facebook' });
  };

  const googleSignIn = () => {
    if (tncAccepted) {
      Auth.federatedSignIn({ provider: 'Google' });
    } else {
      setError('Please accept the Terms and conditions');
    }
  };

  const onFinish = async (values) => {
    let isValidPromo = false;
    if (values['promoCode']) {
      setLoading(true);
      await isPromoBoolean({ promo: values['promoCode'].toUpperCase() }).then(
        (res) => {
          console.log('promo', res.isPromo);
          isValidPromo = res.isPromo;
          if (!res.isPromo) {
            setLoading(false);
            setError('Invalid or Expired Promotional Code');
            return;
          } else {
            setLoading(false);
          }
        },
      );
    }
    setSignUpDetails({ ...values });
    console.log(signUpDetails);
    if (values && values['promoCode'] ? isValidPromo : true) {
      values = { ...values, ...signUpDetails };
      if (tncAccepted) {
        let usernameAndEmail = values['personalEmail']
          ? values['personalEmail']
          : values['Email'] || signUpDetails['Email'];

        usernameAndEmail = usernameAndEmail.toLowerCase();
        const url = new URL(window.location.href);
        const referralUserId = url.searchParams.get('user');

        const payload = {
          username: usernameAndEmail,
          password: values['Password'] || signUpDetails['Password'],
          attributes: {
            given_name: values['firstName'] || signUpDetails['firstName'],
            family_name: values['lastName'] || signUpDetails['lastName'],
            phone_number: `+919999999999`,
            email: usernameAndEmail,
            'custom:type': values['promoCode']
              ? `${values['promoCode']} promo`
              : 'paid',
            'custom:studentprofile': accessUrl ? accessUrl : undefined,
            'custom:schoolemail': values['personalEmail']
              ? values['Email'].toLowerCase()
              : undefined,
            'custom:referralUserId': referralUserId
              ? referralUserId
              : undefined,
          },
        };

        Object.keys(payload.attributes).forEach((key) =>
          payload.attributes[key] === undefined ||
          payload.attributes[key].length === 0
            ? delete payload.attributes[key]
            : {},
        );

        if (isStudent) {
          payload.attributes['custom:type'] = 'student';
        }

        setLoading(true);
        Auth.signUp(payload)
          .then((res) => {
            setVerify(true);
          })
          .catch((error) => {
            setError(error.message);
            errorHandler();
          })
          .finally(() => {
            setLoading(false);
            const url = new URL(window.location.href);
            const referralUserId = url.searchParams.get('referralUserId');
            console.log(referralUserId);
            // editReferralEntry(referralUserId);
          });
      } else {
        setError('Please accept the Terms and conditions');
      }
    }
  };

  return (
    <SignUpForm
      username={username}
      code={code}
      // setFormType={setFormType}
      facebookSignIn={facebookSignIn}
      googleSignIn={googleSignIn}
      onFinish={onFinish}
      isLoading={isLoading}
      isUserVerified={isUserVerified}
      error={error}
      showVerifyMessage={showVerifyMessage}
      tncAccepted={tncAccepted}
      signUpFunction={setSignUpClicked}
      setAccessUrl={setAccessUrl}
      signUpClick={signUpClick}
      setTncAccepted={(val) => setTncAccepted(val)}
      setError={(val) => setError(val)}
      isSignUp={isSignUp}
      history={history}
    />
  );
};

export default SignUp;
