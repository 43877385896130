import React /*, { useState }*/ from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from './style.module.scss';

const FreeTrial: React.FC<Props> = ({ history }) => {
  // const [showMinerviniText, setShowMinerviniText] = useState<boolean>(false);

  return (
    <>
      <div
        style={{ minHeight: '85vh', marginTop: '25px', position: 'relative' }}
      >
        <div
          style={{
            position: 'absolute',
            top: 10,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'top',
            // backgroundImage:
            // 'url("https://thinksabio-asset.s3.us-east-1.amazonaws.com/thinksabioDataSummary.jpg")', // Replace with your image path
            zIndex: 0.2,
            opacity: 0.1,
          }}
        />
        <div style={{ position: 'relative', zIndex: 1 }}>
          <div
            style={{ marginTop: 100, marginLeft: 50 }}
            className={styles.vGoBackSaveBar}
          >
            <p
              onClick={() => history.goBack()}
              className={styles.vGoBackBtnBlock}
            >
              <span className={styles.vGoBackBtn}>
                <ArrowLeftOutlined /> Go Back
              </span>
            </p>
          </div>
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              color: 'var(--whitecolor) !important',
              // marginLeft: '160px',
            }}
          >
            <div
              style={{
                // position: 'absolute',
                margin: 'auto',
                top: '10%',
                color: 'var(--whitecolor) !important',
                padding: '10px',
                borderRadius: '5px',
                fontWeight: 'bold',
                fontSize: '30px',
              }}
            >
              Your Journey to Financial Independence
              <br />
              <span
                style={{
                  color: 'var(--whitecolor) !important',
                }}
              >
                Starts now just for $9.99
              </span>
              <br />
              <span
                style={{
                  fontSize: '20px',
                  color: '#1abd50',
                }}
              >
                Analyze, Invest, Grow
              </span>
            </div>
          </div>
          <div className={styles.freeTrialCardContainer}>
            <div className={styles.freeTrialCard}>
              <div className={styles.paymentCardContent}>
                <p className={styles.cardTitle}>7-Days Free Trial</p>
                <div className={styles.fee}>
                  <p className={styles.amount}>No Credit Card required</p>
                </div>
                <h6>Full access to Web and Telegram Groups</h6>
                <Link to="/sign-up">
                  <p className={styles.membershipBtn}>Get Free Access</p>
                </Link>
              </div>
            </div>
          </div>
          <div
            style={{
              color: 'var(--whitecolor)',
              textAlign: 'center',
              fontSize: '20px',
              marginTop: 20,
              marginBottom: '10px',
            }}
          >
            <span>
              <Link to="/pricing">Click here</Link> for our pricing details
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeTrial;
