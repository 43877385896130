import React from 'react';

import { BarChartOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

import { MultiBarChart } from '../../../components';
import BreadcrumbAndGoBack from '../BreadcrumbAndGoBack2';
import CommonHeader from '../CommonHeader';
import styles from '../style.module.scss';

const IIRReportHeader = (props) => {
  const {
    reportType,
    reportSubType,
    goBack,
    isLoading,
    currentSearchCriteria,
    reportTitle,
    fetchReportName,
    fetchingName,
    breadcrumb,
    isFilterEmpty,
    handleFilterValueClick,
    handleReset,
    history,
    setShowGraph,
    searchReportTitle,
    note,
    compareSectorAllocation,
    setShowAllocation,
    compareIns,
    handleCompareInsClick,
  } = props;
  return (
    <>
      <BreadcrumbAndGoBack
        reportType={reportType}
        reportSubType={reportSubType}
        goBack={goBack}
        isLoading={isLoading}
        currentSearchCriteria={currentSearchCriteria}
        reportTitle={reportTitle}
        fetchReportName={fetchReportName}
        fetchingName={fetchingName}
        breadcrumb={breadcrumb}
        history={history}
      />
      <div className={styles.symbolSearchContentBox}>
        <div
          className={styles.titleContainer}
          style={{
            marginBottom: '12px',
          }}
        >
          <div
            className={styles.titleContent}
            style={{
              gap: '12px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <h3
                style={{
                  lineHeight: '20px',
                  fontSize: '20px',
                }}
              >
                {searchReportTitle}
              </h3>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  textAlign: 'center',
                  margin: '0px',
                  color: 'var(--whitecolor)',
                }}
              >
                <span style={{ color: 'red' }}>
                  {note[reportSubType || reportType]?.length > 2 ? '*' : ''}
                </span>{' '}
                {note[reportSubType || reportType]}
              </p>
            </div>
            {!isLoading && reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT' && (
              <span
                onClick={() => setShowGraph(true)}
                style={{ cursor: 'pointer', fontWeight: 'bold' }}
              >
                <BarChartOutlined
                  style={{ color: '#8884d8', fontSize: '36px' }}
                />
              </span>
            )}
            {reportSubType === 'COMPARE_INSTITUTIONS' && (
              <span
                className={styles.pieChart}
                onClick={() => setShowAllocation(true)}
              >
                <MultiBarChart
                  insData={
                    compareSectorAllocation && compareSectorAllocation.length
                      ? compareSectorAllocation
                      : []
                  }
                  height={60}
                  width={100}
                  showLegend={false}
                />
              </span>
            )}
          </div>
        </div>

        {isFilterEmpty.length > 0 && (
          <div className={styles.filterValueBox}>
            <CommonHeader
              handleClick={handleFilterValueClick}
              handleReset={handleReset}
            />
          </div>
        )}
        {reportSubType === 'COMPARE_INSTITUTIONS' &&
          (compareIns || []).map((ins) => (
            <Tag
              key={ins.cik}
              closable={compareIns.length > 2}
              onClose={() => handleCompareInsClick(ins)}
              color={'success'}
            >
              {ins.name}
            </Tag>
          ))}
      </div>
    </>
  );
};

export default IIRReportHeader;
