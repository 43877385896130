import React from 'react';

import { Select } from 'antd';
import { get } from 'lodash';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { DraggableModal } from '../../components';
import { currencyFormat } from '../../utils';
import { formatLargeNumbers, getGraphData } from './SearchReport.helper';
const { Option } = Select;
const IIRReportGraphicalRep = (props) => {
  const {
    showGraph,
    setShowGraph,
    searchData,
    isLoading,
    graphType,
    onGraphTypeChange,
    reportSubType,
    queryData,
    sectorAllocation,
    selectedQuarter,
    filterOptions,
    CustomCursor,
    getSingleGraphData,
    CustomLegend,
    CustomYAxisTick,
  } = props;
  return (
    <DraggableModal
      visible={showGraph}
      title={
        reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT' ? (
          <p
            style={{ margin: 0, textAlign: 'center' }}
          >{`Graphical Respresentations For 
      ${get(queryData, 'institutionsName', '')} 
      (Total AUM : ${currencyFormat(
        get(sectorAllocation, '0.total', 0),
        false,
        false,
        2,
        false,
        true,
      )} As of ${selectedQuarter.replace('_', '-')} )`}</p>
        ) : (
          <p
            style={{
              margin: 0,
              textAlign: 'center',
              cursor: 'all-scroll',
            }}
          >{`${get(
            filterOptions,
            'category',
            'All Institutions',
          )} Sector Wise Allocation for  ${selectedQuarter.replace(
            '_',
            '-',
          )} Quarter`}</p>
        )
      }
      width={1100}
      onCancel={() => setShowGraph(false)}
      footer={null}
    >
      <div style={{ color: 'var(--whitecolor)' }}>
        Graph Type
        <Select
          defaultValue={graphType}
          style={{ width: 200, marginBottom: '10px' }}
          onChange={(key) => onGraphTypeChange(key)}
          placeholder="Select to Get Graph"
          disabled={isLoading}
        >
          <Option key={'Inst Shares'} value={'Inst Shares'}>
            {'Inst Shares'}
          </Option>
          <Option key={'Inst Counts'} value={'Inst Counts'}>
            {'Inst Counts'}
          </Option>
          <Option key={'Total Shares'} value={'Total Shares'}>
            {'Total Shares'}
          </Option>
          <Option
            key={'Total Shares Change'}
            value={'lastQtrTotalSharesChange'}
          >
            {'Total Shares Change'}
          </Option>
          <Option key={'Current Price'} value={'lastSale'}>
            {'Current Price'}
          </Option>
          <Option key={'Possible Growth'} value={'possGrowth'}>
            {'Possible Growth'}
          </Option>
          <Option key={'Market Value'} value={'marketValue'}>
            {'Market Value'}
          </Option>
          <Option
            key={'Approx Inv Value'}
            value={'lastQtr1ApproxInvestedValue'}
          >
            {'Approx Inv Value'}
          </Option>
          <Option key={'Shares Change'} value={'lastQtrSharesChange'}>
            {'Shares Change'}
          </Option>
          <Option key={'Count Change'} value={'lastQtrCountChange'}>
            {'Count Change'}
          </Option>
          <Option key={'shares Diff'} value={'sharesDiff'}>
            {'shares Diff'}
          </Option>
          <Option key={'Avg Price'} value={'lastQtr1Avg'}>
            {'Avg Price'}
          </Option>
          <Option key={'% Allocation'} value={'allocationPerc'}>
            {'% Allocation'}
          </Option>
          <Option key={'1Yr%'} value={'oneYrGrowth'}>
            {'1Yr%'}
          </Option>
          <Option key={'5Yr%'} value={'fiveYrGrowth'}>
            {'5Yr%'}
          </Option>
          <Option key={'YTD'} value={'ytd'}>
            {'YTD'}
          </Option>
          <Option key={'52 Wk High'} value={'c52WkHigh'}>
            {'52 Wk High'}
          </Option>
          <Option key={'Rank'} value={'rank'}>
            {'Rank'}
          </Option>
        </Select>
      </div>
      <br />
      {['Inst Counts', 'Inst Shares', 'Total Shares'].includes(graphType) ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              data={
                getGraphData(
                  get(searchData, 'data', []),
                  get(searchData, 'columns'),
                  graphType,
                ) || []
              }
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              isAnimationActive={true}
            >
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis
                dataKey="symbol"
                tick={{ fontWeight: 'bold', fontSize: 14, fill: '#1abd50' }}
              />
              <YAxis tick={<CustomYAxisTick />} tickMargin={20} />
              <Tooltip
                formatter={(value: any) => formatLargeNumbers(value)}
                cursor={<CustomCursor />}
              />
              <Legend />
              <Bar
                dataKey={
                  graphType === 'Inst Shares'
                    ? 'lastQtr1Shares'
                    : graphType === 'Inst Counts'
                    ? 'lastQtr1Count'
                    : graphType === 'Total Shares'
                    ? 'totalLastQtr1Shares'
                    : ''
                }
                fill="#8884d8"
                barSize={20}
                animationDuration={500}
                animationEasing="ease-in"
              />
              <Bar
                dataKey={
                  graphType === 'Inst Shares'
                    ? 'lastQtr2Shares'
                    : graphType === 'Inst Counts'
                    ? 'lastQtr2Count'
                    : graphType === 'Total Shares'
                    ? 'totalLastQtr2Shares'
                    : ''
                }
                fill="#82ca9d"
                barSize={20}
                animationDuration={500}
                animationEasing="ease-in"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              data={
                getSingleGraphData(
                  get(searchData, 'data', []),
                  get(searchData, 'columns'),
                ) || []
              }
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              isAnimationActive={true}
              onMouseMove={(state) => {
                console.log('Mouse moved on chart:', state); // Debugging the mouse move event
              }}
            >
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis
                dataKey="symbol"
                tick={{
                  fontWeight: 'bold',
                  fontSize: 14,
                  fill: '#1abd50',
                }}
              />
              <YAxis
                tick={<CustomYAxisTick />}
                tickMargin={20}
                domain={[
                  Math.min(
                    ...getSingleGraphData(
                      get(searchData, 'data', []),
                      get(searchData, 'columns'),
                    ).map((d) => parseFloat(d[graphType])),
                  ),
                  Math.max(
                    ...getSingleGraphData(
                      get(searchData, 'data', []),
                      get(searchData, 'columns'),
                    ).map((d) => parseFloat(d[graphType])),
                  ),
                ]}
              />
              <Tooltip
                formatter={(value: any) => formatLargeNumbers(value)}
                cursor={<CustomCursor />}
              />
              <Legend content={<CustomLegend />} />
              <Bar
                dataKey={graphType}
                fill={(data) => (data.fill ? data.fill : '#8884d8')}
                barSize={20}
                animationDuration={500}
                animationEasing="ease-out"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </DraggableModal>
  );
};

export default IIRReportGraphicalRep;
