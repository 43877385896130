import React from 'react';

import moment from 'moment-timezone';

import { PieChart } from '../../../components';
import { getCurrentSECDateRange } from '../../../utils';
import BreadcrumbAndGoBack from '../BreadcrumbAndGoBack';
// import CommonHeader from '../CommonHeader';
import styles from '../style.module.scss';
const SimpleHeader = (props) => {
  const {
    reportType,
    reportSubType,
    goBack,
    isLoading,
    currentSearchCriteria,
    reportTitle,
    fetchReportName,
    fetchingName,
    breadcrumb,
    isFilterEmpty,
    handleFilterValueClick,
    handleReset,
    history,
    note,
    searchReportTitle,
    trendingStockType,
    lastUpdatedTime,
    setShowAllocation,
    sectorAllocation,
  } = props;
  return (
    <>
      <BreadcrumbAndGoBack
        reportType={reportType}
        reportSubType={reportSubType}
        goBack={goBack}
        isLoading={isLoading}
        currentSearchCriteria={currentSearchCriteria}
        reportTitle={reportTitle}
        fetchReportName={fetchReportName}
        fetchingName={fetchingName}
        breadcrumb={breadcrumb}
        history={history}
        isFilterEmpty={isFilterEmpty}
        handleFilterValueClick={handleFilterValueClick}
        handleReset={handleReset}
      >
        <div
          className={styles.symbolSearchContentBox}
          style={{
            borderBottom: 'none',
          }}
        >
          <div
            className={styles.titleContainer}
            style={{
              marginBottom: '0px',
            }}
          >
            <div
              className={styles.titleContent}
              style={{
                gap: '12px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                <h3
                  style={{
                    lineHeight: '20px',
                    fontSize: '20px',
                  }}
                >
                  {searchReportTitle}
                </h3>
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                    textAlign: 'center',
                    margin: '0px',
                    color: 'var(--whitecolor)',
                  }}
                >
                  <span style={{ color: 'red' }}>
                    {note[reportSubType || reportType]?.length > 2 ? '*' : ''}
                  </span>{' '}
                  {note[reportSubType || reportType]}
                  {!(
                    (trendingStockType !== 'GAINERS' &&
                      trendingStockType !== 'DECLINERS') ||
                    reportSubType !== 'TRENDING_STOCK'
                  ) && (
                    <span>{`  ( Note: Excluded Symbols Below $1 Current Price )`}</span>
                  )}
                </p>

                {reportSubType === 'TS_WEEKLY_REPORT' && (
                  <span
                    style={{ fontSize: '12px', color: 'var(--whitecolor)' }}
                  >
                    * Consistenly Going Up Since Atleast 2 Weeks ( The End price
                    is &gt; $1 )
                    <br />
                  </span>
                )}
                {(reportSubType === 'BY_APPROX_TXN_VAL' ||
                  reportSubType === 'INDIVIDUAL_STATUS_REPORT') && (
                  <>
                    <span
                      hidden={reportSubType === 'INDIVIDUAL_STATUS_REPORT'}
                      style={{
                        fontSize: '15px',
                        color: 'var(--whitecolor)',
                      }}
                    >
                      ( Period :{' '}
                      {moment(getCurrentSECDateRange().start).format(
                        'MMMM DD, YYYY',
                      )}{' '}
                      to{' '}
                      {moment(lastUpdatedTime)
                        .tz('America/New_York')
                        .format('MMMM DD, YYYY')}{' '}
                      )
                    </span>
                  </>
                )}
                {reportSubType === 'C52WKHL' && (
                  <span
                    style={{ fontSize: '12px', color: 'var(--whitecolor)' }}
                  >
                    Stocks that have doubled from 52-Week Low or increased
                    Atleast by 50%
                  </span>
                )}
              </div>
              {reportSubType === 'ALLOCATIONS' && (
                <span
                  className={styles.pieChart}
                  onClick={() => setShowAllocation(true)}
                >
                  <PieChart
                    height={50}
                    width={50}
                    radius={20}
                    dataKey={'allocationPercentage'}
                    data={
                      sectorAllocation && sectorAllocation.length
                        ? sectorAllocation
                        : []
                    }
                  />
                </span>
              )}
            </div>
          </div>
        </div>
      </BreadcrumbAndGoBack>
      {/* {isFilterEmpty.length > 0 && (
        <div className={styles.filterValueBox}>
          <CommonHeader
            handleClick={handleFilterValueClick}
            handleReset={handleReset}
          />
        </div>
      )} */}
    </>
  );
};

export default SimpleHeader;
