import React, { useEffect, useState } from 'react';

import './News.css';
import { getNewsSymbolData } from '../../graphql'; // Import the CSS file for styling

const News = () => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const symbol = window.location.href.split('/').pop(); // Assuming symbol is part of URL

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const url = `https://finnhub.io/api/v1/company-news?symbol=${symbol}&from=${
          new Date(new Date().setDate(new Date().getDate() - 2))
            .toISOString()
            .split('T')[0]
        }&to=${new Date().toISOString().split('T')[0]}`;
        const response = await getNewsSymbolData(url);
        console.log(response);
        const filteredNews = (response?.newsData || []).filter(
          (news) => news.summary && news.image,
        );

        setNewsData(filteredNews);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching news:', error);
        setLoading(false);
      }
    };

    fetchNews();
  }, [symbol]);

  const renderNewsCard = (news) => {
    const firstLineOfSummary = news.summary.split('.')[0] + '.';

    return (
      <div className="news-card" key={news.id}>
        <div className="news-card-left">
          {/* Render image only if it exists */}
          {news.image && (
            <img className="news-image" src={news.image} alt={news.headline} />
          )}
        </div>
        <div className="news-card-right">
          <h3 className="news-headline">{news.headline}</h3>
          <p className="news-summary">{firstLineOfSummary}</p>
          <p className="news-source">Source: {news.source}</p>
          <a
            href={news.url}
            className="news-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read More
          </a>
        </div>
      </div>
    );
  };

  return (
    <>
      <h1
        hidden={!newsData?.length}
        style={{
          color: '#1abd50',
          textAlign: 'center',
          margin: 'auto',
          paddingBottom: '30px',
        }}
      >
        Latest News
      </h1>
      <br />
      <div className="news-container">
        {loading ? (
          <p>Loading...</p>
        ) : newsData.length > 0 ? (
          newsData.map((news) => renderNewsCard(news))
        ) : (
          <p>No news available</p>
        )}
      </div>
    </>
  );
};

export default News;
