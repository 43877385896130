import React from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { get } from 'lodash';

import CommonHeader from './CommonHeader';
import SaveReport from './SaveReport';
import styles from './style.module.scss';

const BreadcrumbAndGoBack = (props) => {
  const {
    reportType,
    reportSubType,
    goBack,
    isLoading,
    currentSearchCriteria,
    reportTitle,
    fetchReportName,
    fetchingName,
    breadcrumb,
    history,
    children,
    isFilterEmpty,
    handleFilterValueClick,
    handleReset,
  } = props;
  return (
    <div
      style={{
        borderBottom: '1px solid #1ABD50',
        paddingBottom: '12px',
      }}
    >
      <div
        className={styles.vGoBackSaveBar}
        style={{
          //borderBottom: '1px solid #1ABD50',
          gap: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '8px',
            flexDirection: 'column',
          }}
        >
          <p
            onClick={() =>
              get(history, 'location.state.prevPage', '') === 'search'
                ? history.push({
                    pathname: '/search',
                    state: {
                      type: reportType,
                      subType: reportSubType,
                      prevPage: 'searchReport',
                    },
                  })
                : goBack()
            }
            className={styles.vGoBackBtnBlock}
            style={{
              margin: '0px',
            }}
          >
            {history?.length > 2 && (
              <span className={styles.vGoBackBtn}>
                <ArrowLeftOutlined /> Go Back
              </span>
            )}
          </p>
          <Breadcrumb>
            {breadcrumb.map((item) => (
              <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>
        {children}

        <div className={styles.SaveReportBoxNew2}>
          <SaveReport
            searchCriteria={currentSearchCriteria}
            reportType={reportType}
            reportSubType={reportSubType}
            reportTitle={reportTitle}
            fetchReportName={fetchReportName}
            fetchingName={fetchingName}
            isButtonLoading={isLoading}
          />
        </div>
      </div>

      {/* {isLoading && <div className={styles.SaveReportBoxNew2}></div>} */}
      {isFilterEmpty?.length > 0 && (
        <div className={styles.filterValueBox}>
          <CommonHeader
            handleClick={handleFilterValueClick}
            handleReset={handleReset}
          />
        </div>
      )}
    </div>
  );
};

export default BreadcrumbAndGoBack;
