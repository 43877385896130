import React, { useState } from 'react';

import { Drawer, Tabs } from 'antd';
import { get } from 'lodash';

import { FilterForm, SortForm } from '../../components';
import { getTimeRange, getTimeFrameFromDate } from '../../utils';
import { filterFields } from './SearchReport.constants';
import { FilterFormType } from './SearchReportTypes';
const { TabPane } = Tabs;

const SearchReportFilterForm = ({
  visible,
  onCancel,
  onFilter,
  filterOptions,
  reportSubType,
  trendingStockType,
  sortDetail,
  columns,
  reportType,
  queryData,
  selectedNoOfQtrs,
}) => {
  const initialFields = () => {
    let fieldArr = sortDetail.map((item, index) => ({
      sortBy: get(item, 'sortBy'),
      sortOrder: get(item, 'sortOrder'),
      index: index,
      name: index,
    }));
    if (fieldArr.length === 0) {
      fieldArr.push({
        sortBy: '',
        sortOrder: 'DESC',
        index: fieldArr.length,
        name: fieldArr.length,
      });
    }

    return fieldArr;
  };

  const [formFields, setFormFields] = useState(initialFields());
  const [submitForm, setSubmitForm] = useState(false);

  const onFilterFinish = (values: FilterFormType) => {
    const {
      minPrice,
      maxPrice,
      minP52WkLow,
      maxP52WkLow,
      minP52WkHigh,
      maxP52WkHigh,
      minInstShares,
      maxInstShares,
      minAproxTxnValue,
      minAproxInvValue,
      maxAproxTxnValue,
      minEntryPrice,
      maxEntryPrice,
      sector,
      minPerChange,
      maxPerChange,
      minPercentage,
      maxPercentage,
      minCountChange,
      minSharesChange,
      minTotalSharesChange,
      maxTotalSharesChange,
      minVolumeChange,
      maxVolumeChange,
      minAvgChange,
      maxAvgChange,
      minGrowth,
      minYTD,
      maxYTD,
      min1Yr,
      max1Yr,
      min5Yr,
      minInstCount,
      minCount,
      maxCount,
      minAllocatedPerc,
      maxAllocatedPerc,
      noOfQuarters,
      // individualSpecial,
      // individualTop10,
      multiSector,
      excludeMultiSector,
      multiIndustry,
      insiderName,
      insiderTitle,
      isTenPercent,
      endDate,
      startDate,
      fromDate,
      entryStartDate,
      entryEndDate,
      minVolDiff,
      maxVolDiff,
      status,
      criterion,
      minOwnership,
      multiStatus,
      multiMarketCap,
      minPerReturn,
      minSecInstCount,
      minSecInsiderCount,
      minSec13dCount,
      minSharesChangePer,
      min1DMA,
      min5DMA,
      min10DMA,
      min20DMA,
      min50DMA,
      min100DMA,
      min150DMA,
      min200DMA,
      minOneDayChange,
      maxOneDayChange,
      minGain,
      maxGain,
      minLoss,
      maxLoss,
      minDayGain,
      maxDayGain,
      minDayLoss,
      maxDayLoss,
      maxInstCount,
      country,
      minPrevQInstCount,
      maxPrevQInstCount,
      minAum,
      maxAum,
      minPosReturnsPerc,
      maxPosReturnsPerc,
      minNegReturnsPerc,
      maxNegReturnsPerc,
      minNegReturnsStockCount,
      maxNegReturnsStockCount,
      minPosReturnsStockCount,
      maxPosReturnsStockCount,
      minTotalCount,
      maxTotalCount,
      minPrevAum,
      maxPrevAum,
      hedgeCount,
      minMarketCap,
      maxMarketCap,
      maxCountChange,
      week,
      minStartPrice,
      maxStartPrice,
      minEndPrice,
      maxEndPrice,
      seriesName,
      maxPossibleGrowth,
      minPossibleGrowth,
      minRank,
      maxRank,
      maxSharesChange,
      institutions,
      minInsiderTransactionValue,
      maxInsiderTransactionValue,
      minTop10HedgeFundsIn_NC_NB_CNT,
      maxTop10HedgeFundsIn_NC_NB_CNT,
      minTop10InstIn_NC_NB_CNT,
      maxTop10InstIn_NC_NB_CNT,
      minLatestWeekPer,
      maxLatestWeekPer,
      queryDate,
    } = values;
    const symbols = values.symbols
      ? values.symbols
      : queryData['symbols']
      ? queryData['symbols']
      : null;
    const filerName = values.filerName
      ? values.filerName
      : queryData['filerName']
      ? queryData['filerName']
      : null;

    const insiderTitles2 = {
      CEO: 'Chief Executive officer',
      CFO: 'Chief Financial Officer',
      COO: 'Chief Operating Officer',
      CBO: 'Chief Business Officer',
      GC: 'General Counsel',
      CCO: 'Chief Commercial Officer',
      CMO: 'Chief Marketing Officer',
      CAO: 'Chief Accounting Officer',
      VP: 'Vice President',
      SVP: 'Senior Vice president',
      EVP: 'Executive vice president',
      'CO-CEO': 'CO-Chief Executive officer',
      Founder: 'founder',
      President: 'pres',
      Chairman: 'chair',
      Director: 'Dir',
      'Co-Founder': 'Co Founder',
      Others: 'others',
    };
    const newFilterOptions: FilterFormType = {};
    if (startDate) {
      newFilterOptions.startDate = startDate.format('YYYY-MM-DD');
    }
    if (endDate) {
      newFilterOptions.endDate = endDate.format('YYYY-MM-DD');
    }
    if (entryStartDate) {
      newFilterOptions.entryStartDate = entryStartDate.format('YYYY-MM-DD');
    }
    if (entryEndDate) {
      newFilterOptions.entryEndDate = entryEndDate.format('YYYY-MM-DD');
    }
    if (fromDate) {
      newFilterOptions.fromDate = fromDate.format('YYYY-MM-DD');
    }
    if (minStartPrice) {
      newFilterOptions.minStartPrice = minStartPrice;
    }
    if (minInstShares) {
      newFilterOptions.minInstShares = minInstShares;
    }
    if (maxInstShares) {
      newFilterOptions.maxInstShares = maxInstShares;
    }
    if (minAproxTxnValue) {
      newFilterOptions.minAproxTxnValue = minAproxTxnValue;
    }
    if (minAproxInvValue) {
      newFilterOptions.minAproxInvValue = minAproxInvValue;
    }
    if (maxAproxTxnValue) {
      newFilterOptions.maxAproxTxnValue = maxAproxTxnValue;
    }
    if (week) {
      newFilterOptions.week = week;
    }
    if (maxStartPrice) {
      newFilterOptions.maxStartPrice = maxStartPrice;
    }
    if (minEndPrice) {
      newFilterOptions.minEndPrice = minEndPrice;
    }
    if (maxEndPrice) {
      newFilterOptions.maxEndPrice = maxEndPrice;
    }
    if (minVolDiff) {
      newFilterOptions.minVolDiff = minVolDiff;
    }
    if (maxVolDiff) {
      newFilterOptions.maxVolDiff = maxVolDiff;
    }
    if (criterion) {
      newFilterOptions.criterion = criterion;
    }
    if (symbols) {
      newFilterOptions.symbols = symbols;
    }
    if (status) {
      newFilterOptions.status = status;
    }
    if (multiSector) {
      newFilterOptions.multiSector = multiSector;
    }
    if (excludeMultiSector) {
      newFilterOptions.excludeMultiSector = excludeMultiSector;
    }
    if (multiIndustry) {
      newFilterOptions.multiIndustry = multiIndustry;
    }
    if (insiderName) {
      newFilterOptions.insiderName = insiderName;
    }
    if (isTenPercent) {
      newFilterOptions.isTenPercent = isTenPercent;
    }
    if (insiderTitle) {
      newFilterOptions.insiderTitle = insiderTitle;
      console.log(newFilterOptions.insiderTitle);
      const titles = [...insiderTitle];
      for (let i of titles) {
        if (
          !newFilterOptions.insiderTitle.includes(insiderTitles2[i]) &&
          i !== 'others'
        ) {
          newFilterOptions.insiderTitle.push(insiderTitles2[i]);
        }
      }
      console.log(newFilterOptions.insiderTitle);
    }
    if (institutions) {
      newFilterOptions.institutions = institutions;
    }
    if (sector) {
      newFilterOptions.sector = sector;
    }
    if (multiStatus) {
      newFilterOptions.multiStatus = multiStatus;
    }
    if (multiMarketCap) {
      newFilterOptions.multiMarketCap = multiMarketCap;
    }
    if (filterOptions.noOfWeeks) {
      const noOfWeeks = filterOptions.noOfWeeks;
      newFilterOptions.noOfWeeks = noOfWeeks;
    }
    if (filterOptions.noOfWeeklyYTDWeeks) {
      const noOfWeeklyYTDWeeks = filterOptions.noOfWeeklyYTDWeeks;
      newFilterOptions.noOfWeeklyYTDWeeks = noOfWeeklyYTDWeeks;
    }
    if (minSec13dCount || +minSec13dCount === 0) {
      newFilterOptions.minSec13dCount = minSec13dCount;
    }
    if (queryDate || +queryDate === 0) {
      newFilterOptions.queryDate = queryDate;
    }
    if (min1DMA || +min1DMA === 0) {
      newFilterOptions.min1DMA = min1DMA;
    }
    if (min5DMA || +min5DMA === 0) {
      newFilterOptions.min5DMA = min5DMA;
    }
    if (min10DMA || +min10DMA === 0) {
      newFilterOptions.min10DMA = min10DMA;
    }
    if (min20DMA || +min20DMA === 0) {
      newFilterOptions.min20DMA = min20DMA;
    }
    if (min50DMA || +min50DMA === 0) {
      newFilterOptions.min50DMA = min50DMA;
    }
    if (min100DMA || +min100DMA === 0) {
      newFilterOptions.min100DMA = min100DMA;
    }
    if (min150DMA || +min150DMA === 0) {
      newFilterOptions.min150DMA = min150DMA;
    }
    if (min200DMA || +min200DMA === 0) {
      newFilterOptions.min200DMA = min200DMA;
    }
    if (minOneDayChange || +minOneDayChange === 0) {
      newFilterOptions.minOneDayChange = minOneDayChange;
    }

    if (minGrowth || +minGrowth === 0) {
      newFilterOptions.minGrowth = minGrowth;
    }
    if (country || +country === 0) {
      newFilterOptions.country = country;
    }
    if (minPrevQInstCount || +minPrevQInstCount === 0) {
      newFilterOptions.minPrevQInstCount = minPrevQInstCount;
    }
    if (maxPrevQInstCount || +maxPrevQInstCount === 0) {
      newFilterOptions.maxPrevQInstCount = maxPrevQInstCount;
    }
    if (minAum || +minAum === 0) {
      newFilterOptions.minAum = minAum;
    }
    if (maxAum || +maxAum === 0) {
      newFilterOptions.maxAum = maxAum;
    }
    if (minTotalCount || +minTotalCount === 0) {
      newFilterOptions.minTotalCount = minTotalCount;
    }
    if (maxTotalCount || +maxTotalCount === 0) {
      newFilterOptions.maxTotalCount = maxTotalCount;
    }
    if (minPosReturnsStockCount || +minPosReturnsStockCount === 0) {
      newFilterOptions.minPosReturnsStockCount = minPosReturnsStockCount;
    }
    if (maxPosReturnsStockCount || +maxPosReturnsStockCount === 0) {
      newFilterOptions.maxPosReturnsStockCount = maxPosReturnsStockCount;
    }
    if (minNegReturnsStockCount || +minNegReturnsStockCount === 0) {
      newFilterOptions.minNegReturnsStockCount = minNegReturnsStockCount;
    }
    if (maxNegReturnsStockCount || +maxNegReturnsStockCount === 0) {
      newFilterOptions.maxNegReturnsStockCount = maxNegReturnsStockCount;
    }
    if (minNegReturnsPerc || +minNegReturnsPerc === 0) {
      newFilterOptions.minNegReturnsPerc = minNegReturnsPerc;
    }
    if (maxNegReturnsPerc || +maxNegReturnsPerc === 0) {
      newFilterOptions.maxNegReturnsPerc = maxNegReturnsPerc;
    }
    if (minPosReturnsPerc || +minPosReturnsPerc === 0) {
      newFilterOptions.minPosReturnsPerc = minPosReturnsPerc;
    }
    if (maxPosReturnsPerc || +maxPosReturnsPerc === 0) {
      newFilterOptions.maxPosReturnsPerc = maxPosReturnsPerc;
    }
    if (minPrevAum || +minPrevAum === 0) {
      newFilterOptions.minPrevAum = minPrevAum;
    }
    if (maxPrevAum || +maxPrevAum === 0) {
      newFilterOptions.maxPrevAum = maxPrevAum;
    }
    if (hedgeCount || +hedgeCount === 0) {
      newFilterOptions.hedgeCount = hedgeCount;
    }
    if (minMarketCap || +minMarketCap === 0) {
      newFilterOptions.minMarketCap = minMarketCap;
    }
    if (maxMarketCap || +maxMarketCap === 0) {
      newFilterOptions.maxMarketCap = maxMarketCap;
    }

    if (noOfQuarters || +noOfQuarters === 0) {
      newFilterOptions.noOfQuarters = noOfQuarters;
    }
    if (minYTD || minYTD === 0) {
      newFilterOptions.ytd = {
        ...newFilterOptions.ytd,
        min: minYTD,
      };
    }
    if (maxYTD || maxYTD === 0) {
      newFilterOptions.ytd = {
        ...newFilterOptions.ytd,
        max: maxYTD,
      };
    }
    if (min1Yr || min1Yr === 0) {
      newFilterOptions.oneYrGrowth = {
        ...newFilterOptions.oneYrGrowth,
        min: min1Yr,
      };
    }
    if (max1Yr || max1Yr === 0) {
      newFilterOptions.oneYrGrowth = {
        ...newFilterOptions.oneYrGrowth,
        max: max1Yr,
      };
    }
    if (minOwnership || minOwnership === 0) {
      newFilterOptions.ownership = {
        min: minOwnership,
      };
    }

    if (min5Yr || min5Yr === 0) {
      newFilterOptions.fiveYrGrowth = {
        min: min5Yr,
      };
    }
    if (minInstCount || minInstCount === 0) {
      newFilterOptions.instCount = {
        ...newFilterOptions.instCount,
        min: minInstCount,
      };
    }
    if (maxInstCount || maxInstCount === 0) {
      newFilterOptions.instCount = {
        ...newFilterOptions.instCount,
        max: maxInstCount,
      };
    }
    if (minCount || minCount === 0) {
      newFilterOptions.minCount = minCount;
    }
    if (maxCount || maxCount === 0) {
      newFilterOptions.maxCount = maxCount;
    }
    if (minAllocatedPerc || +minAllocatedPerc === 0) {
      newFilterOptions.minAllocatedPerc = minAllocatedPerc;
    }
    if (maxAllocatedPerc || +maxAllocatedPerc === 0) {
      newFilterOptions.maxAllocatedPerc = maxAllocatedPerc;
    }

    if (minInsiderTransactionValue || +minInsiderTransactionValue === 0) {
      newFilterOptions.minInsiderTransactionValue = minInsiderTransactionValue;
    }
    if (maxInsiderTransactionValue || +maxInsiderTransactionValue === 0) {
      newFilterOptions.maxInsiderTransactionValue = maxInsiderTransactionValue;
    }
    if (minPrice || +minPrice === 0) {
      newFilterOptions.minPrice = minPrice;
    }
    if (maxPrice || +maxPrice === 0) {
      newFilterOptions.maxPrice = maxPrice;
    }
    if (minP52WkLow || +minP52WkLow === 0) {
      newFilterOptions.minP52WkLow = minP52WkLow;
    }
    if (maxP52WkLow || +maxP52WkLow === 0) {
      newFilterOptions.maxP52WkLow = maxP52WkLow;
    }
    if (minP52WkHigh || +minP52WkHigh === 0) {
      newFilterOptions.minP52WkHigh = minP52WkHigh;
    }
    if (maxP52WkHigh || +maxP52WkHigh === 0) {
      newFilterOptions.maxP52WkHigh = maxP52WkHigh;
    }

    if (minEntryPrice || +minEntryPrice === 0) {
      newFilterOptions.minEntryPrice = minEntryPrice;
    }
    if (maxEntryPrice || +maxEntryPrice === 0) {
      newFilterOptions.maxEntryPrice = maxEntryPrice;
    }
    if (maxCountChange || +maxCountChange === 0) {
      newFilterOptions.maxCountChange = maxCountChange;
    }
    if (filerName) {
      newFilterOptions.filerName = filerName;
    }
    if (seriesName) {
      newFilterOptions.seriesName = seriesName;
    }
    if (maxPossibleGrowth || +maxPossibleGrowth === 0) {
      newFilterOptions.maxPossibleGrowth = maxPossibleGrowth;
    }
    if (minPossibleGrowth || +minPossibleGrowth === 0) {
      newFilterOptions.minPossibleGrowth = minPossibleGrowth;
    }
    if (maxRank || +maxRank === 0) {
      newFilterOptions.maxRank = maxRank;
    }
    if (minRank || +minRank === 0) {
      newFilterOptions.minRank = minRank;
    }
    if (maxSharesChange || +maxSharesChange === 0) {
      newFilterOptions.maxSharesChange = maxSharesChange;
    }
    if (minCountChange || +minCountChange === 0) {
      newFilterOptions.minCountChange = minCountChange;
    }
    if (minSharesChange || +minSharesChange === 0) {
      newFilterOptions.minSharesChange = minSharesChange;
    }
    if (minTotalSharesChange || +minTotalSharesChange === 0) {
      newFilterOptions.minTotalSharesChange = minTotalSharesChange;
    }
    if (maxTotalSharesChange || +maxTotalSharesChange === 0) {
      newFilterOptions.maxTotalSharesChange = maxTotalSharesChange;
    }
    if (minVolumeChange || +minVolumeChange === 0) {
      newFilterOptions.minVolumeChange = minVolumeChange;
    }
    if (maxVolumeChange || +maxVolumeChange === 0) {
      newFilterOptions.maxVolumeChange = maxVolumeChange;
    }
    if (minAvgChange || +minAvgChange === 0) {
      newFilterOptions.minAvgChange = minAvgChange;
    }
    if (maxAvgChange || +maxAvgChange === 0) {
      newFilterOptions.maxAvgChange = maxAvgChange;
    }
    if (minPerChange || +minPerChange === 0) {
      newFilterOptions.minPerChange = minPerChange;
    }
    if (maxPerChange || +maxPerChange === 0) {
      newFilterOptions.maxPerChange = maxPerChange;
    }
    if (minPercentage || +minPercentage === 0) {
      newFilterOptions.minPercentage = minPercentage;
    }
    if (maxPercentage || +maxPercentage === 0) {
      newFilterOptions.maxPercentage = maxPercentage;
    }
    if (minPerReturn || +minPerReturn === 0) {
      newFilterOptions.minPerReturn = minPerReturn;
    }
    if (minSecInstCount || +minSecInstCount === 0) {
      newFilterOptions.minSecInstCount = minSecInstCount;
    }
    if (minSecInsiderCount || +minSecInsiderCount === 0) {
      newFilterOptions.minSecInsiderCount = minSecInsiderCount;
    }
    if (minSharesChangePer || +minSharesChangePer === 0) {
      newFilterOptions.minSharesChangePer = minSharesChangePer;
    }
    if (maxOneDayChange || +maxOneDayChange === 0) {
      newFilterOptions.maxOneDayChange = maxOneDayChange;
    }
    if (minGain || +minGain === 0) {
      newFilterOptions.minGain = minGain;
    }
    if (maxGain || +maxGain === 0) {
      newFilterOptions.maxGain = maxGain;
    }

    if (minLoss || +minLoss === 0) {
      newFilterOptions.minLoss = minLoss;
    }
    if (maxLoss || +maxLoss === 0) {
      newFilterOptions.maxLoss = maxLoss;
    }
    if (minDayGain || +minDayGain === 0) {
      newFilterOptions.minDayGain = minDayGain;
    }
    if (maxDayGain || +maxDayGain === 0) {
      newFilterOptions.maxDayGain = maxDayGain;
    }

    if (minDayLoss || +minDayLoss === 0) {
      newFilterOptions.minDayLoss = minDayLoss;
    }
    if (maxDayLoss || +maxDayLoss === 0) {
      newFilterOptions.maxDayLoss = maxDayLoss;
    }
    if (
      minTop10HedgeFundsIn_NC_NB_CNT ||
      +minTop10HedgeFundsIn_NC_NB_CNT === 0
    ) {
      newFilterOptions.minTop10HedgeFundsIn_NC_NB_CNT =
        minTop10HedgeFundsIn_NC_NB_CNT;
    }
    if (
      maxTop10HedgeFundsIn_NC_NB_CNT ||
      +maxTop10HedgeFundsIn_NC_NB_CNT === 0
    ) {
      newFilterOptions.maxTop10HedgeFundsIn_NC_NB_CNT =
        maxTop10HedgeFundsIn_NC_NB_CNT;
    }
    if (minTop10InstIn_NC_NB_CNT || +minTop10InstIn_NC_NB_CNT === 0) {
      newFilterOptions.minTop10InstIn_NC_NB_CNT = minTop10InstIn_NC_NB_CNT;
    }
    if (maxTop10InstIn_NC_NB_CNT || +maxTop10InstIn_NC_NB_CNT === 0) {
      newFilterOptions.maxTop10InstIn_NC_NB_CNT = maxTop10InstIn_NC_NB_CNT;
    }
    if (minLatestWeekPer || +minLatestWeekPer === 0) {
      newFilterOptions.minLatestWeekPer = minLatestWeekPer;
    }
    if (maxLatestWeekPer || +maxLatestWeekPer === 0) {
      newFilterOptions.maxLatestWeekPer = maxLatestWeekPer;
    }
    const sort = [];
    formFields.forEach((s) => {
      if (s.sortBy) {
        sort.push({
          sortOrder: s.sortOrder,
          sortBy: s.sortBy,
        });
      }
    });
    console.log('newFilterOptions', newFilterOptions);
    let tf = null;
    if (reportSubType === 'SECTOR_TRENDING') {
      tf = getTimeFrameFromDate(
        newFilterOptions.startDate,
        newFilterOptions.endDate,
      );
    }
    onFilter(newFilterOptions, sort, tf);
    onCancel();
  };
  const onReset = () => {
    const dateRange = getTimeRange('1D');
    onFilter(
      reportSubType === 'SECTOR_TRENDING'
        ? {
            endDate: dateRange.end,
            startDate: dateRange.start,
          }
        : {},
      [],
      reportSubType === 'SECTOR_TRENDING' ? '1D' : null,
      true,
    );
    onCancel();
  };

  const onSortFinish = (values) => {
    setSubmitForm(true);
  };

  return (
    <Drawer
      placement="right"
      onClose={onCancel}
      visible={visible}
      getContainer={false}
      closable={true}
      style={{
        // top: 220,
        //height: 'calc(100% - 220px)',
        height: '100%',
        boxShadow:
          '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
      }}
      // mask={false}
      bodyStyle={{
        paddingTop: 5,
      }}
      width={450}
    >
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Filter" key="1">
          <FilterForm
            onReset={onReset}
            onFilter={onFilterFinish}
            filterOptions={filterOptions}
            fields={filterFields(
              reportSubType || reportType,
              trendingStockType,
              filterOptions,
              queryData,
              selectedNoOfQtrs,
            )}
            reportCode={reportSubType || reportType}
            source="search"
            submitForm={submitForm}
          />
        </TabPane>
        <TabPane tab="Sort" key="2">
          <SortForm
            columns={columns}
            isIIR={reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT'}
            formFields={formFields}
            setFormFields={setFormFields}
            onReset={onReset}
            onSubmit={onSortFinish}
          />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default SearchReportFilterForm;
