import React, { useState } from 'react';

import { GoogleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Row, Col, Input, Form, Button, Checkbox } from 'antd';
import { RouteComponentProps, Link, useLocation } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import { Tnc } from '../../components';
import { green, isStudentUrl } from '../../utils';
import styles from './style.module.scss';

interface Props extends RouteComponentProps<any> {
  username: string;
  setTncAccepted: Function;
  signUpClick: boolean;
  signUpFunction: Function;
  setAccessUrl: Function;
  setError: Function;
  showVerifyMessage: boolean;
  onFinish: Function;
  tncAccepted: boolean;
  isLoading: boolean;
  facebookSignIn: Function;
  isUserVerified: boolean;
  googleSignIn: Function;
  isSignUp: boolean;
  error: string;
}

// const IconFont = createFromIconfontCN({
//   scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
// });

const SignUpForm: React.FC<Props> = ({
  username,
  setTncAccepted,
  setError,
  showVerifyMessage,
  signUpFunction,
  onFinish,
  setAccessUrl,
  tncAccepted,
  signUpClick,
  isLoading,
  facebookSignIn,
  isUserVerified,
  googleSignIn,
  isSignUp,
  error,
  history,
}) => {
  const prevPage =
    history && history.location && history.location.state
      ? history.location.state?.prevPage
      : null;
  console.log('history at signup page', history);
  const location = useLocation();
  const isPromo = location.state?.name?.properties?.name === 'promo';
  const [openTnc, setOpenTnc] = useState<boolean>(false);
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [isPromoUser, setIsPromoUser] = useState<boolean>(false);
  const url = new URL(window.location.href);
  const promoCode = url.searchParams.get('promo');
  // const [signUpClick, setSignUpClicked] = useState<boolean>(false);
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const setToDefaultState = () => {
    signUpFunction(false);
  };

  const onCheckboxChange = (e) => {
    // setTncAccepted(e.target.checked);
    setIsPromoUser(!isPromoUser);
  };

  const isStudent = isStudentUrl();
  return (
    <div className={styles.container}>
      {/* <h3>{username ? 'VERIFY' : 'REGISTER'}</h3> */}
      <div className={styles.signupContent}>
        <div className={styles.signupContentImage}>
          <img
            alt="Sign up"
            src={
              'https://thinksabio-asset.s3.amazonaws.com/ThinkSabio_signup_edited.png'
            }
          />
          {/* <div className={styles.imageBottomtext}>
            <h4>ThinkSabio</h4>
          </div> */}
        </div>
        <div className={styles.signupContentText}>
          <div className={styles.logotext}>
            <h4>
              {signUpClick && !showVerifyMessage ? (
                <div>
                  <span
                    onClick={() => setToDefaultState()}
                    className={styles.vGoBackBtn}
                  >
                    <ArrowLeftOutlined />
                  </span>
                </div>
              ) : (
                <Link to={prevPage ? prevPage : '/'}>
                  {' '}
                  <ArrowLeftOutlined />{' '}
                </Link>
              )}
            </h4>
            <div className={styles.logoImage}>
              <Link to="/">
                {' '}
                <img
                  alt="logo"
                  src="https://thinksabio-asset.s3.amazonaws.com/TSLOGO.png"
                />
              </Link>
            </div>
          </div>
          <div className={styles.formContentText}>
            <div className={styles.formContentTextBox}>
              {!username && !showVerifyMessage && (
                <Form
                  style={{ color: 'white !important' }}
                  {...layout}
                  name="sign-up"
                  onFinish={onFinish}
                  onValuesChange={() => setError('')}
                  initialValues={{
                    agreement: true,
                    Email: '',
                    promoCode: promoCode,
                  }}
                >
                  {isStudent || isPromo || promoCode ? (
                    ''
                  ) : (
                    <>
                      <Form.Item>
                        <Row span={24} justify="end">
                          <Col
                            style={{ marginTop: isEmail ? '' : '150px' }}
                            span={24}
                          >
                            <Button
                              size="large"
                              style={{
                                width: '100%',
                              }}
                              className={styles.googleBtn}
                              onClick={googleSignIn}
                            >
                              <GoogleOutlined />
                              Sign up with Google
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                      <p className={styles.ortext}>
                        <hr />
                        <span style={{ paddingTop: '10px' }}>OR</span>
                        <hr />
                      </p>
                    </>
                  )}
                  {
                    <>
                      <Col hidden={isEmail} span={24}>
                        <Button
                          size="large"
                          style={{
                            width: '100%',
                          }}
                          className={styles.mailBtn}
                          onClick={() => {
                            setIsEmail(true);
                          }}
                        >
                          Create Account
                        </Button>
                      </Col>
                      <div hidden={!isEmail}>
                        <Form.Item
                          name="firstName"
                          label="First Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter First Name!',
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter First Name"
                            autoComplete="off"
                          />
                        </Form.Item>
                        <Form.Item
                          name="lastName"
                          label="Last Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Last Name!',
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Last Name"
                            autoComplete="off"
                          />
                        </Form.Item>
                        <Form.Item
                          // hasFeedback
                          name="Email"
                          label="Email"
                          rules={[
                            {
                              type: 'email',
                              required: true,
                              message: 'Please enter valid Email!',
                            },
                          ]}
                        >
                          <Input
                            placeholder={
                              isStudent
                                ? 'Please Enter Your Email Id'
                                : 'xyz@example.com'
                            }
                            autoComplete="false"
                          />
                        </Form.Item>
                        <Form.Item
                          name="Password"
                          label="Password"
                          className={styles.passwordField}
                          rules={[
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                let regex =
                                  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\w~@#$%^&*+=`|{}:;!.?"()[\]-]{8,16}$/;
                                if (!value) {
                                  return Promise.reject('Password is required');
                                }
                                if (!regex.test(value)) {
                                  return Promise.reject(
                                    'Password must be 8-16 characters and contain both numbers and letters/special characters.',
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            placeholder="Enter Password"
                            autoComplete="off"
                          />
                        </Form.Item>
                        <Checkbox
                          checked={isPromoUser}
                          onChange={onCheckboxChange}
                        >
                          Have Promo Code ?
                        </Checkbox>
                        {isPromo || promoCode || isPromoUser ? (
                          <Form.Item
                            name="promoCode"
                            label="Promo Code"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Promo Code!',
                              },
                            ]}
                          >
                            <Input placeholder="Enter Promo Code" />
                          </Form.Item>
                        ) : (
                          ''
                        )}
                        <Col span={24}>
                          <Form.Item>
                            <span
                              style={{
                                color: 'var(--whitecolor)',
                                marginRight: '10px',
                              }}
                            >
                              By clicking Sign Up, I agree to the
                            </span>
                            <Link
                              to="/tnc"
                              // onClick={() => setOpenTnc(true)}
                              className={styles.tnc}
                            >
                              Terms and Conditions & Privacy Policy
                            </Link>
                          </Form.Item>
                        </Col>
                        <Form.Item>
                          <Button
                            className={styles.signUp}
                            type="primary"
                            htmlType="submit"
                            style={{ width: '100%' }}
                            loading={isLoading}
                            size="large"
                          >
                            Sign Up
                          </Button>
                        </Form.Item>
                        <p style={{ color: 'red', marginBottom: '15px' }}>
                          {error}
                        </p>
                      </div>
                    </>
                  }
                  <p className={styles.anAccount}>
                    {isStudent ? (
                      <>
                        Issue with the signup?&nbsp;
                        <Link to="/contact-us">Contact Us</Link>
                      </>
                    ) : (
                      ''
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp; Already have an account?
                    <Link to="/login"> Login</Link>
                  </p>
                  <p className={styles.anAccount}></p>
                </Form>
              )}

              {!isSignUp && (
                <>
                  {isLoading && (
                    <div className={styles.loaderStyle}>
                      <BounceLoader
                        color={green}
                        loading={isLoading}
                        size={150}
                      />
                    </div>
                  )}
                  {error && (
                    <p style={{ color: 'red', marginBottom: '15px' }}>
                      {error}
                    </p>
                  )}
                  {isUserVerified && (
                    <div className={styles.verifiedBox}>
                      <div className={styles.verifiedBoxContent}>
                        <h3>Email Successfully Verified.</h3>
                        <button>
                          <Link to="/login">Login</Link>
                        </button>
                        {/* Your account is verified now, please{' '} */}
                        {/* <Link to="/login">
                          <span
                            style={{
                              color: green,
                              cursor: 'pointer',
                            }}
                            // onClick={() => {
                            //   // setFormType('login');
                            //   history.push('/login');
                            // }}
                            // onClick={() => history.push('/')}
                          >
                            {` login`}
                          </span>
                        </Link> */}
                      </div>
                    </div>
                  )}
                </>
              )}

              {showVerifyMessage && (
                <div className={styles.showVerify}>
                  <div className={styles.verifiedBoxContent}>
                    <img
                      alt="mail_icon"
                      src="https://thinksabio-asset.s3.amazonaws.com/Mailer-icon.png"
                    />
                    <h3>You are almost there.</h3>
                    <p>
                      Your account has been created successfully. Please check
                      your inbox and click on the verification link
                    </p>
                  </div>
                </div>
              )}
              <Tnc
                isVisible={openTnc}
                onCancel={() => {
                  setOpenTnc(false);
                }}
                onAgree={() => {
                  setOpenTnc(false);
                  setTncAccepted(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
