import React, { useState, useEffect } from 'react';

import queryString from 'query-string';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import {
  getCompanyProfileSymbolData,
  getDayTrendSymbolData,
  getRecommendationSymbolData,
} from '../../graphql';
import { formatNumberWithPercent } from '../../utils/helpers';
import { DayTrend } from '../index';
import News from './NewsPage';
import './News.css';

const StackedBarChart: React.FC<Props> = ({ history }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [price, setPrice] = useState(0);
  const [previousPrice, setPreviousPrice] = useState(0);
  const [priceDifference, setPriceDifference] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0);
  const [error, setError] = useState(null);
  const [retries, setRetries] = useState(0);
  const [companyProfile, setCompanyProfile] = useState({});
  const [socket, setSocket] = useState(null);
  const socketUrl = `wss://ws.finnhub.io?token=cnsr81hr01qt1mk8sdk0cnsr81hr01qt1mk8sdkg`;
  const symbol = window.location.href.split('/').pop();

  const fetchLastPrice = async () => {
    try {
      let url = `https://finnhub.io/api/v1/stock/candle?symbol=${symbol}&resolution=D`;
      const response = await getDayTrendSymbolData(url);
      setPrice(response.dayTrendData.c[0] || 'N/A');
    } catch (error) {
      console.error('Error fetching last price', error);
      setError('N/A');
    }
  };
  const formatLargeNumbers = (n: number) => {
    if (n === 0) return '0';

    const sign = n < 0 ? '-' : '';
    const absNumber = Math.abs(n);

    const denominations = [
      { value: 1e12, symbol: 'T' }, // Trillions
      { value: 1e9, symbol: 'B' }, // Billions
      { value: 1e6, symbol: 'M' }, // Millions
      { value: 1e3, symbol: 'K' }, // Thousands
      { value: 1, symbol: '' }, // Base case (no abbreviation)
    ];

    for (const { value, symbol } of denominations) {
      if (absNumber >= value) {
        const formattedNumber = (absNumber / value).toFixed(symbol ? 1 : 0);
        return `${sign}${parseFloat(formattedNumber).toString()}${symbol}`;
      }
    }

    return `${sign}${n}`;
  };
  const initializeSocket = () => {
    const socketInstance = new WebSocket(socketUrl);
    let dataReceived = false;

    socketInstance.addEventListener('open', function () {
      console.log('WebSocket connection established');
      socketInstance.send(
        JSON.stringify({ type: 'subscribe', symbol: symbol }),
      );
      setLoading(false);
    });

    socketInstance.addEventListener('message', function (event) {
      const data = JSON.parse(event.data);
      console.log('WebSocket Data:', data);

      if (data.type === 'trade') {
        const latestPrice = data.data[0]?.p;
        if (latestPrice) {
          dataReceived = true;
          setPrice(latestPrice);
        }
      }
    });

    socketInstance.addEventListener('error', function (error) {
      console.error('WebSocket error');
    });
    socketInstance.addEventListener('close', function () {
      console.log('WebSocket connection closed');
      if (!dataReceived) {
        fetchLastPrice();
      }

      // Retry logic with exponential backoff
      if (retries < 5) {
        const retryDelay = Math.pow(2, retries) * 3000; // Exponential backoff
        setRetries((prev) => prev + 1); // Increment retries after calculating delay

        console.log(
          `Reconnecting in ${retryDelay / 1000} seconds... Attempt ${
            retries + 1
          }`,
        );
        setTimeout(() => initializeSocket(), retryDelay);
      } else {
        console.log('WebSocket connection failed after multiple attempts');
      }
    });

    setSocket(socketInstance);

    const pingInterval = setInterval(() => {
      if (socketInstance.readyState === WebSocket.OPEN) {
        socketInstance.send(JSON.stringify({ type: 'ping' }));
      }
    }, 30000); // Send ping every 30 seconds

    // Cleanup function for component unmount or socket closure
    return () => {
      clearInterval(pingInterval); // Cleanup ping interval
      if (socketInstance) {
        socketInstance.close(); // Close WebSocket connection
      }
    };
  };
  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      const barColors = {
        buy: '#8884d8',
        hold: '#82ca9d',
        sell: '#ffc658',
        strongBuy: 'green',
        strongSell: '#ff6666',
      };

      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: 'white',
            padding: '10px',
            border: '1px solid #ccc',
          }}
        >
          <p style={{ color: 'black' }}>{`Date: ${data.date}`}</p>
          {['buy', 'hold', 'sell', 'strongBuy', 'strongSell'].map((key) => (
            <p
              style={{ color: 'black', display: 'flex', alignItems: 'center' }}
              key={key}
            >
              <span
                style={{
                  display: 'inline-block',
                  width: '12px',
                  height: '12px',
                  marginRight: '10px',
                  backgroundColor: barColors[key],
                }}
              ></span>
              {`${key.charAt(0).toUpperCase() + key.slice(1)}: ${data[key]}`}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  // Fetch chart data and last price on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://finnhub.io/api/v1/stock/recommendation?symbol=${symbol}`;
        const profileUrl = `https://finnhub.io/api/v1/stock/profile?symbol=${symbol}`;
        const response = await getRecommendationSymbolData(url);
        const response1 = await getCompanyProfileSymbolData(profileUrl);

        const apiData = response.recommendationData;
        const apiProfileData = response1?.companyProfileData;
        setCompanyProfile(apiProfileData);
        console.log(companyProfile);
        const currentDate = new Date();
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(currentDate.getMonth() - 8);

        const filteredData = apiData.filter((item) => {
          const periodDate = new Date(item.period);
          return periodDate >= threeMonthsAgo && periodDate <= currentDate;
        });

        const uniqueDates = new Set();
        const formattedData = filteredData
          .filter((item) => {
            const periodDate = new Date(item.period).toLocaleDateString(
              'en-US',
              {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              },
            );

            if (!uniqueDates.has(periodDate)) {
              uniqueDates.add(periodDate);
              return true;
            }
            return false;
          })
          .map((item) => ({
            period: new Date(item.period).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
            }),
            date: new Date(item.period).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }),
            buy: item.buy,
            hold: item.hold,
            sell: item.sell,
            strongBuy: item.strongBuy,
            strongSell: item.strongSell,
          }));

        setChartData(formattedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data', error);
        setLoading(false);
      }
    };

    fetchData();
    fetchLastPrice();
    initializeSocket();

    return () => {
      if (socket) {
        socket.close(); // Cleanup socket on unmount
      }
    };
  }, [symbol, retries]);

  // Handle price difference and percentage change calculation
  useEffect(() => {
    if (price && previousPrice) {
      const difference = price - previousPrice;
      const percentage = ((difference / previousPrice) * 100).toFixed(2);
      setPriceDifference(difference.toFixed(2));
      setPercentageChange(percentage);
    }
    if (price !== previousPrice) {
      setPreviousPrice(price);
    }
  }, [price]); // Recalculate whenever the price changes

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginLeft: '230px',
          marginTop: '120px',
          marginBottom: '100px',
        }}
      >
        <div>
          <h1
            style={{ color: '#2FC058', cursor: 'pointer' }}
            onClick={() => {
              let filters = {};
              filters = {
                type: 'SYMBOL'.toLowerCase(),
                subtype: 'INDIVIDUAL_STATISTICAL_REPORT'.toLowerCase(),
                symbols: symbol,
              };
              history.push(`/search/report/` + queryString.stringify(filters));
            }}
          >
            {symbol}
            <span
              style={{
                color: 'var(--whitecolor)',
                fontSize: '24px',
                marginLeft: '20px',
              }}
            >
              {companyProfile['name'] ? companyProfile['name'] : ''}
            </span>
          </h1>
          <p
            style={{
              color: 'var(--whitecolor)',
              marginTop: '-20px',
            }}
          >
            {companyProfile['gsector'] ? companyProfile['gsector'] + ' | ' : ''}
            <span>
              {companyProfile['gsubind'] ? companyProfile['gsubind'] : ''}
            </span>
          </p>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p style={{ color: 'red' }}>{error}</p>
          ) : (
            <p
              style={{
                fontSize: '28px',
                fontWeight: 'bold',
                color: 'var(--whitecolor)',
              }}
            >
              ${price || 'N/A'}
              {priceDifference !== 0 && (
                <span
                  style={{
                    color: priceDifference > 0 ? 'green' : 'red',
                    marginLeft: '10px',
                    fontSize: '18px',
                  }}
                >
                  {priceDifference > 0
                    ? `+${priceDifference}`
                    : priceDifference}{' '}
                  (
                  {priceDifference > 0
                    ? `+${percentageChange}%`
                    : `${percentageChange}%`}
                  )
                </span>
              )}
            </p>
          )}
        </div>

        {/* Table Section */}
        <div style={{ flex: 1, marginLeft: '20px', marginTop: '50px' }}>
          <table className="teslaInfoTable">
            <thead>
              <tr>
                <th>Market Capitalization</th>
                <th>Outstanding Shares</th>
                <th>Institutional Ownership</th>
                <th>Insider Ownership</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  $
                  {formatLargeNumbers(
                    Number(companyProfile['marketCapitalization']) * 1000000,
                  )}
                </td>
                <td>
                  {formatLargeNumbers(
                    parseInt(companyProfile['shareOutstanding']) * 1000000,
                  )}
                </td>
                <td>
                  {formatNumberWithPercent(
                    companyProfile['institutionOwnership'],
                  )}
                </td>
                <td>
                  {formatNumberWithPercent(companyProfile['insiderOwnership'])}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        style={{
          marginTop: '-200px',
          marginLeft: '150px',
        }}
      >
        <DayTrend isHomeSearch={true} />
      </div>
      {chartData.length > 0 ? (
        <div
          style={{
            width: '60%',
            height: 400,
            marginTop: '200px !important',
            marginBottom: '200px !important',
            margin: 'auto',
          }}
        >
          <h1
            style={{
              color: '#1abd50',
              margin: 'auto',
              paddingBottom: '30px',
              paddingLeft: 200,
            }}
          >
            Analyst Recommendations
          </h1>
          <ResponsiveContainer width="95%" height="100%">
            <BarChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="period" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="buy" stackId="a" fill="#8884d8" barSize={20} />
              <Bar dataKey="hold" stackId="a" fill="#82ca9d" barSize={20} />
              <Bar dataKey="sell" stackId="a" fill="#ffc658" barSize={20} />
              <Bar dataKey="strongBuy" stackId="a" fill="green" barSize={20} />
              <Bar
                dataKey="strongSell"
                stackId="a"
                fill="#ff6666"
                barSize={20}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <p>No data available</p>
      )}
      <div style={{ flex: 1, marginTop: 100 }}>
        <News />
      </div>
    </div>
  );
};

export default StackedBarChart;
