import React, { useState, useEffect } from 'react';

import {
  Form,
  InputNumber,
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  Input,
} from 'antd';
import { get } from 'lodash';
import moment from 'moment-timezone';
import { getHolidays } from 'nyse-holidays';
import { useSelector, shallowEqual } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';

import {
  esSearchCompany,
  esSearchAllInstitution,
  getFilerNames,
  getSeriesNames,
} from '../../graphql';
import { green, getTimeRange } from '../../utils';
import {
  insiderTitles,
  maxFieldNames,
  minFieldNames,
  formatter,
} from './FilterForm.helper';
import MaxField from './MaxField';
import MinField from './MinField';
import styles from './style.module.scss';

const { Option } = Select;

interface Props {
  onFilter: Function;
  filterOptions: Object;
  fields: [];
  onReset: Function;
  reportCode: string;
  source: string;
  submitForm: boolean;
}

const FilterForm: React.FC<Props> = ({
  onFilter,
  filterOptions,
  fields,
  onReset,
  reportCode,
  source,
  submitForm,
}) => {
  const [disableStart, setDisableStart] = useState<Date>(null);
  const { sectorOptions, industriesOptions, countryOptions } = useSelector(
    ({ report }) => ({
      sectorOptions: get(report, 'sectors', []),
      industriesOptions: get(report, 'industries', []),
      countryOptions: get(report, 'countries', [
        {
          name: 'China',
          value: 'China',
        },
      ]),
    }),
    shallowEqual,
  );
  const fetchSeries = async (value) => {
    setFetching(true);
    const res = await getSeriesNames(value);
    if (res) {
      setSeriesData(res);
    }
    setFetching(false);
  };

  const [form] = Form.useForm();
  const [seiresData, setSeriesData] = useState([]);
  const [currentField, setCurrentField] = useState<string>('');
  const [institutionsData, setInstitutionsData] = useState([]);
  const [symbolData, setSymbolData] = useState([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [loadingCik, setLoadingCik] = useState<boolean>(false);
  const [selectedInstitutions, setSelectedInstitutions] = useState([]);
  const [fundNameData, setFundNameData] = useState([]);
  // const [fetching, setFetching] = useState<boolean>(false);
  const fetchFilerNames = async (value) => {
    setFetching(true);
    const res = await getFilerNames(value);
    if (res) {
      setFundNameData(res);
    }
    setFetching(false);
  };
  useEffect(() => {
    if (submitForm) {
      form.submit();
    }
  }, [form, submitForm]);

  const fetchSymbols = async (value) => {
    setFetching(true);
    const res = await esSearchCompany(value);
    if (res) {
      setSymbolData(res);
    }
    setFetching(false);
  };
  const fetchInstitution = async (value) => {
    setLoadingCik(true);
    const res = await esSearchAllInstitution(value);
    setInstitutionsData(res);
    setLoadingCik(false);
  };

  const onFinish = (values) => {
    const newValues = { ...values };
    if (
      !(source === 'reports' || source === 'search' || source === 'trending')
    ) {
      delete newValues.institutions;
    }
    onFilter(newValues);
  };
  const handleSelect = (value) => {
    const ins = selectedInstitutions;
    ins.push(value.key);
    setSelectedInstitutions(ins);
    setInstitutionsData([]);
  };

  const handleDeSelect = (value) => {
    const ins = selectedInstitutions.filter((i) => i !== value.key);
    setSelectedInstitutions(ins);
  };

  // const getFromDate = () => {
  //   return null;
  //   if (moment().day() === 1) {
  //     return moment().subtract(3, 'day');
  //   }
  //   if (moment().day() === 0) {
  //     return moment().subtract(2, 'day');
  //   }
  //   return moment().subtract(1, 'day');
  // };

  const getInitialValues = () => {
    const startDate = get(filterOptions, 'startDate', '');
    const endDate = get(filterOptions, 'endDate', '');
    const entryStartDate = get(filterOptions, 'entryStartDate', '');
    const entryEndDate = get(filterOptions, 'entryEndDate', '');
    const fromDate = get(filterOptions, 'fromDate', '');
    if (source === 'search') {
      return {
        ...filterOptions,
        minYTD: get(filterOptions, 'ytd.min', ''),
        maxYTD: get(filterOptions, 'ytd.max', ''),
        min1Yr: get(filterOptions, 'oneYrGrowth.min', ''),
        max1Yr: get(filterOptions, 'oneYrGrowth.max', ''),
        min5Yr: get(filterOptions, 'fiveYrGrowth.min', ''),
        minInstCount: get(filterOptions, 'instCount.min', ''),
        maxInstCount: get(filterOptions, 'instCount.max', ''),
        minInstShares: get(filterOptions, 'minInstShares', ''),
        maxInstShares: get(filterOptions, 'maxInstShares', ''),
        minCount: get(filterOptions, 'minCount', ''),
        maxCount: get(filterOptions, 'maxCount', ''),
        minAllocationPerc: get(filterOptions, 'minAllocationPerc', ''),
        maxAllocationPerc: get(filterOptions, 'maxAllocationPerc', ''),
        minAproxTxnValue: get(filterOptions, 'minAproxTxnValue', ''),
        minAproxInvValue: get(filterOptions, 'minAproxInvValue', ''),
        maxAproxTxnValue: get(filterOptions, 'maxAproxTxnValue', ''),
        minPrevQInstCount: get(filterOptions, 'minPrevQInstCount', ''),
        maxPrevQInstCount: get(filterOptions, 'maxPrevQInstCount', ''),
        minAum: get(filterOptions, 'minAum', ''),
        maxAum: get(filterOptions, 'maxAum', ''),
        minTotalCount: get(filterOptions, 'minTotalCount', ''),
        maxTotalCount: get(filterOptions, 'maxTotalCount', ''),
        minPosReturnsStockCount: get(
          filterOptions,
          'minPosReturnsStockCount',
          '',
        ),
        maxPosReturnsStockCount: get(
          filterOptions,
          'maxPosReturnsStockCount',
          '',
        ),
        minNegReturnsStockCount: get(
          filterOptions,
          'minNegReturnsStockCount',
          '',
        ),
        maxNegReturnsStockCount: get(
          filterOptions,
          'maxNegReturnsStockCount',
          '',
        ),
        minNegReturnsPerc: get(filterOptions, 'minNegReturnsPerc', ''),
        maxNegReturnsPerc: get(filterOptions, 'maxNegReturnsPerc', ''),
        minPosReturnsPerc: get(filterOptions, 'minPosReturnsPerc', ''),
        maxPosReturnsPerc: get(filterOptions, 'maxPosReturnsPerc', ''),
        minPrevAum: get(filterOptions, 'minPrevAum', ''),
        maxPrevAum: get(filterOptions, 'maxPrevAum', ''),
        hedgeCount: get(filterOptions, 'hedgeCount', ''),
        minMarketCap: get(filterOptions, 'minMarketCap', ''),
        maxMarketCap: get(filterOptions, 'maxMarketCap', ''),
        minOwnership: get(filterOptions, 'ownership.min', ''),
        week: get(filterOptions, 'week', ''),
        minStartPrice: get(filterOptions, 'minStartPrice', ''),
        maxStartPrice: get(filterOptions, 'maxStartPrice', ''),
        minP52WkHigh: get(filterOptions, 'minP52WkHigh', ''),
        maxP52WkHigh: get(filterOptions, 'maxP52WkHigh', ''),
        minP52WkLow: get(filterOptions, 'minP52WkLow', ''),
        maxP52WkLow: get(filterOptions, 'maxP52WkLow', ''),
        minEndPrice: get(filterOptions, 'minEndPrice', ''),
        maxEndPrice: get(filterOptions, 'maxEndPrice', ''),
        startDate: startDate
          ? moment(startDate)
          : reportCode === 'TOP_GAINERS'
          ? moment(getTimeRange('1D', true).start)
          : reportCode === 'WEEKLY_GAINS_REPORT'
          ? null
          : moment().subtract(1, 'year'),
        endDate: endDate
          ? moment(endDate)
          : reportCode === 'TOP_GAINERS'
          ? moment(getTimeRange('1D', true).end)
          : reportCode === 'WEEKLY_GAINS_REPORT'
          ? null
          : moment(),
        entryEndDate: entryEndDate ? moment(entryEndDate) : null,
        entryStartDate: entryStartDate ? moment(entryStartDate) : null,
        noOfWeeks: get(filterOptions, 'noOfWeeks', null),
        noOfDays: get(filterOptions, 'noOfDays', null),
        fromDate: fromDate ? moment(fromDate) : null,
      };
    } else {
      return {
        ...filterOptions,
        startDate: startDate ? moment(startDate) : moment().subtract(1, 'year'),
        endDate: endDate ? moment(endDate) : moment(),
        fromDate: fromDate ? moment(fromDate) : null,
        noOfWeeks: get(filterOptions, 'noOfWeeks', null),
        noOfDays: get(filterOptions, 'noOfDays', null),
      };
    }
  };

  const onValuesChange = (changedValues: Object) => {
    if (changedValues.minPrice) {
      form.validateFields(['maxPrice'], { force: true });
    }
    if (changedValues.maxPrice) {
      form.validateFields(['minPrice'], { force: true });
    }
  };
  // const isResetBtnDisable = () => {
  //   return (
  //     filterOptions.minPrice ||
  //     filterOptions.maxPrice ||
  //     filterOptions.symbols ||
  //     filterOptions.minPerChange ||
  //     filterOptions.minCountChange ||
  //     filterOptions.minSharesChange ||
  //     filterOptions.minGrowth ||
  //     filterOptions.minYTD ||
  //     filterOptions.min1Yr ||
  //     filterOptions.min5Yr ||
  //     filterOptions.minInstCount ||
  //     filterOptions.minSecInstCount ||
  //     filterOptions.minSecInsiderCount ||
  //     filterOptions.noOfWeeks ||
  //     filterOptions.noOfQuarters ||
  //     filterOptions.type ||
  //     filterOptions.sector ||
  //     filterOptions.startDate ||
  //     filterOptions.endDate ||
  //     filterOptions.status ||
  //     filterOptions.multiStatus ||
  //     filterOptions.minOwnership ||
  //     filterOptions.criterion
  //   );
  // };
  const selectList = (options: Array<string>) =>
    options.map((p) => (
      <Option key={p} value={p}>
        {p}
      </Option>
    ));

  const selectObjectList = (options: Array<{ name: string; value: string }>) =>
    options.map((p) => (
      <Option key={p.value} value={p.value}>
        {p.name}
      </Option>
    ));
  const checkData = (currentDate) => {
    const year = moment(currentDate).year();
    const holidays = getHolidays(year).map((d) => d.dateString);
    let result = holidays.includes(moment(currentDate).format('YYYY-MM-DD'));
    return result;
  };
  const checkDataTo = (currentDate) => {
    const year = moment(currentDate).year();
    const holidays = getHolidays(year).map((d) => d.dateString);
    let result =
      holidays.includes(moment(currentDate).format('YYYY-MM-DD')) ||
      moment(currentDate).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD');
    let result1 = disableStart
      ? moment(currentDate).format('YYYY-MM-DD') <
        moment(disableStart).add(1, 'days').format('YYYY-MM-DD')
      : false;
    return result || result1;
  };
  const checkDataFrom = (currentDate) => {
    const year = moment(currentDate).year();
    const holidays = getHolidays(year).map((d) => d.dateString);
    let result = holidays.includes(moment(currentDate).format('YYYY-MM-DD'));
    return result;
  };
  return (
    <Form
      name="filter-form"
      onFinish={onFinish}
      labelCol={{ span: 24, padding: 0 }}
      wrapperCol={{ span: 24 }}
      form={form}
      onValuesChange={onValuesChange}
      initialValues={getInitialValues()}
      style={{ height: '88vh' }}
    >
      <Row
        span={24}
        className={styles.filterFormScroll}
        //style={{
        //   height: 'calc(100% - 50px)',
        //maxHeight: 550,
        // maxHeight: 'calc(100vh - 42vh)',
        // overflow: 'auto',
        //</Form>/}}
      >
        {(fields || []).map((field) => (
          <Col span={field.size} key={field.name}>
            {field.type === 'symbol' && (
              <Form.Item
                name={field.name}
                label={'Enter Symbol'}
                style={{ marginBottom: 5 }}
              >
                <Select
                  placeholder="Enter valid symbol"
                  notFoundContent={
                    fetching ? (
                      <span className={styles.spinnerStyle}>
                        <BounceLoader color={green} loading={true} size={50} />
                      </span>
                    ) : null
                  }
                  onSearch={fetchSymbols}
                  showSearch={true}
                  allowClear={true}
                  showArrow={false}
                  filterOption={false}
                  style={{ width: '95%' }}
                  onSelect={() => setSymbolData([])}
                  size="middle"
                >
                  {symbolData.map((d) => (
                    <Option key={d.symbol} className={styles.optionStyle}>
                      <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
                      {d.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {field.type === 'symbols' && (
              <Form.Item
                name={field.name}
                label={'Enter Symbols'}
                style={{ marginBottom: 5 }}
              >
                <Select
                  placeholder="Enter valid symbol"
                  mode="multiple"
                  notFoundContent={
                    fetching ? (
                      <span className={styles.spinnerStyle}>
                        <BounceLoader color={green} loading={true} size={50} />
                      </span>
                    ) : null
                  }
                  onSearch={fetchSymbols}
                  showSearch={true}
                  allowClear={true}
                  showArrow={false}
                  filterOption={false}
                  style={{ width: '95%' }}
                  onSelect={() => setSymbolData([])}
                  size="middle"
                >
                  {symbolData.map((d) => (
                    <Option key={d.symbol} className={styles.optionStyle}>
                      <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
                      {d.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {field.type === 'ciks' && (
              <Form.Item
                name={field.name}
                label={'Select Institutions'}
                style={{ marginBottom: 5 }}
              >
                <Select
                  mode="multiple"
                  showSearch
                  placeholder="Enter Institution"
                  notFoundContent={
                    loadingCik ? (
                      <span className={styles.spinnerStyle}>
                        <BounceLoader color={green} loading={true} size={50} />
                      </span>
                    ) : null
                  }
                  filterOption={false}
                  onSearch={fetchInstitution}
                  style={{ width: '95%' }}
                  size="middle"
                  onSelect={(value, key) => handleSelect(key)}
                  onDeselect={(value, key) => handleDeSelect(key)}
                >
                  {institutionsData.map((institution) => (
                    <Option
                      key={institution.cik + '-#-' + institution.name}
                      value={institution.name}
                    >
                      {institution.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {field.type === 'insider' && (
              <Form.Item
                name={field.name}
                label={field.label}
                style={{ marginBottom: 5 }}
              >
                <Input
                  style={{ width: '95%' }}
                  placeholder={field.placeholder}
                  // type={field.allowDecimal ? '' : 'number'}
                  // min={field.allowNegative ? '' : 0}
                />
              </Form.Item>
            )}
            {(field.type === 'sector' || field.type === 'multiSector') && (
              <Form.Item
                name={field.name}
                title={
                  'sector filter is applied only along with count or allocation % filter'
                }
                style={
                  reportCode === 'INSTITUTION_ALLOCATION_REPORT'
                    ? { marginBottom: 5, marginRight: 20 }
                    : { marginBottom: 5 }
                }
                label={
                  reportCode === 'INSTITUTION_ALLOCATION_REPORT'
                    ? 'Apply Filter on Specific Sectors'
                    : 'Sector'
                }
              >
                <Select
                  placeholder="Sector"
                  showSearch
                  style={{ width: '90%' }}
                  mode={field.multiple ? 'multiple' : false}
                >
                  {sectorOptions.map((p, i) => (
                    <Option key={p.value + i} value={p.value}>
                      {p.name.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {field.type === 'fundName' && (
              <Form.Item
                name={field.name}
                style={{ marginBottom: 5 }}
                label="Mutual Fund"
              >
                <Select
                  notFoundContent={
                    fetching ? (
                      <span className={styles.spinnerStyle}>
                        <BounceLoader color={green} loading={true} size={50} />
                      </span>
                    ) : null
                  }
                  onSearch={fetchFilerNames}
                  showSearch
                  placeholder="Fund"
                  size="large"
                >
                  {fundNameData.map((p, i) => (
                    <Option key={p.value + i} value={p.value}>
                      {p.name.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {field.type === 'isTenPercent' && (
              <Form.Item
                name={field.name}
                style={{ marginBottom: 5 }}
                label={field.label}
              >
                <Select
                  placeholder={field.placeholder}
                  style={{ width: '90%' }}
                  mode={false}
                >
                  <Option value={'YES'}>YES</Option>
                  <Option value={'NO'}>NO</Option>
                </Select>
              </Form.Item>
            )}
            {field.type === 'insiderTitle' && (
              <Form.Item
                name={field.name}
                style={{ marginBottom: 5 }}
                label={field.label}
              >
                <Select
                  placeholder={field.placeholder}
                  showSearch
                  style={{ width: '90%' }}
                  mode={'multiple'}
                >
                  {(insiderTitles || []).map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {field.type === 'seriesName' && (
              <Form.Item
                name={field.name}
                style={{ marginBottom: 5 }}
                label="Series Name"
              >
                <Select
                  placeholder="Series Name"
                  notFoundContent={
                    fetching ? (
                      <span className={styles.spinnerStyle}>
                        <BounceLoader color={green} loading={true} size={50} />
                      </span>
                    ) : null
                  }
                  onSearch={fetchSeries}
                  showSearch={true}
                  allowClear={true}
                  showArrow={false}
                  filterOption={false}
                  onSelect={() => setSeriesData([])}
                  style={{ width: '90%' }}
                  mode={field.multiple ? 'multiple' : false}
                >
                  {seiresData.map((p, i) => (
                    <Option key={p.value + i} value={p.value}>
                      {p.name.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {field.type === 'excludeMultiSector' && (
              <Form.Item
                name={field.name}
                style={{ marginBottom: 5 }}
                label="Exclude Sector"
              >
                <Select
                  placeholder="Exclude Sector"
                  showSearch
                  style={{ width: '90%' }}
                  mode={field.multiple ? 'multiple' : false}
                >
                  {sectorOptions.map((p, i) => (
                    <Option key={p.value + i} value={p.value}>
                      {p.name.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {field.type === 'multiIndustry' && (
              <Form.Item
                name={field.name}
                style={{ marginBottom: 5 }}
                label="Industry"
              >
                <Select
                  placeholder="Industry"
                  showSearch
                  style={{ width: '90%' }}
                  mode={field.multiple ? 'multiple' : false}
                >
                  {industriesOptions.map((p, i) => (
                    <Option key={p.value + i} value={p.value}>
                      {p.name.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {field.type === 'country' && (
              <Form.Item
                name={field.name}
                style={{ marginBottom: 5 }}
                label="Country"
              >
                <Select
                  placeholder="Country"
                  showSearch
                  style={{ width: '90%' }}
                  mode={field.multiple ? 'multiple' : false}
                >
                  {countryOptions.map((p, i) => (
                    <Option key={p.value + i} value={p.value}>
                      {p.name.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {field.type === 'minPrice' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minPrice"
                maxFieldname="maxPrice"
                currentField={currentField}
                showDollar={true}
                min={0}
                setCurrentField={setCurrentField}
                errorMessage="Min Price should be less than Max Price"
              />
            )}
            {field.type === 'maxPrice' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minPrice"
                maxFieldname="maxPrice"
                currentField={currentField}
                setCurrentField={setCurrentField}
                showDollar={true}
                min={0}
                errorMessage="Max Price should be greater than Min Price"
              />
            )}
            {field.type === 'minP52WkLow' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minP52WkLow"
                maxFieldname="maxP52WkLow"
                currentField={currentField}
                showDollar={true}
                min={0}
                setCurrentField={setCurrentField}
                errorMessage="Min 52WkLow should be less than Max 52WkLow"
              />
            )}
            {field.type === 'maxP52WkLow' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minP52WkLow"
                maxFieldname="maxP52WkLow"
                currentField={currentField}
                setCurrentField={setCurrentField}
                showDollar={true}
                min={0}
                errorMessage="Max 52WkLow should be greater than Min 52WkLow"
              />
            )}
            {field.type === 'minP52WkHigh' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minP52WkHigh"
                maxFieldname="maxP52WkHigh"
                currentField={currentField}
                showDollar={true}
                min={0}
                setCurrentField={setCurrentField}
                errorMessage="Min 52WkHigh should be less than Max 52WkHigh"
              />
            )}
            {field.type === 'maxP52WkHigh' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minP52WkHigh"
                maxFieldname="maxP52WkHigh"
                currentField={currentField}
                setCurrentField={setCurrentField}
                showDollar={true}
                min={0}
                errorMessage="Max 52WkHigh should be greater than Min 52WkHigh"
              />
            )}
            {field.type === 'minEntryPrice' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minEntryPrice"
                maxFieldname="maxEntryPrice"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min Entry Price should be less than Max Entry Price"
              />
            )}
            {field.type === 'maxEntryPrice' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minEntryPrice"
                maxFieldname="maxEntryPrice"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max Entry Price should be greater than Min Entry Price"
              />
            )}
            {field.type === 'minInsiderTransactionValue' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minInsiderTransactionValue"
                maxFieldname="maxInsiderTransactionValue"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min Transaction value should be less than Max Transaction Value"
              />
            )}
            {field.type === 'maxInsiderTransactionValue' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minInsiderTransactionValue"
                maxFieldname="maxInsiderTransactionValue"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max Transaction value should be greater than Min Transaction value"
              />
            )}
            {field.type === 'minInstCount' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minInstCount"
                maxFieldname="maxInstCount"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min Inst Count should be less than Max Inst Count"
              />
            )}
            {field.type === 'maxInstCount' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minInstCount"
                maxFieldname="maxInstCount"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max Inst Count should be greater than Min Inst count"
              />
            )}
            {field.type === 'minCount' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minCount"
                maxFieldname="maxCount"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min Count should be less than Max Count"
              />
            )}
            {field.type === 'maxCount' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minCount"
                maxFieldname="maxCount"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max Count should be greater than Min count"
              />
            )}
            {field.type === 'minInstShares' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minInstShares"
                maxFieldname="maxInstShares"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min Inst Shares should be less than Max Inst Shares"
              />
            )}
            {field.type === 'maxInstShares' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minInstShares"
                maxFieldname="maxInstShares"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max Inst Shares should be greater than Min Inst Shares"
              />
            )}
            {field.type === 'minAproxTxnValue' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minAproxTxnValue"
                maxFieldname="maxAproxTxnValue"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min Approx Txn Val should be less than Max Approx Txn Val"
              />
            )}
            {field.type === 'minAproxInvValue' && (
              <MinField
                Form={Form}
                field={field}
                currentField={currentField}
                setCurrentField={setCurrentField}
              />
            )}
            {field.type === 'maxAproxTxnValue' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="maxAproxTxnValue"
                maxFieldname="minAproxTxnValue"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max Approx Txn Val should be greater than Min Approx Txn Val"
              />
            )}

            {field.type === 'minLiveCash' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minLiveCash"
                maxFieldname="maxLiveCash"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min Live Cash should be less than Max Live Cash"
              />
            )}
            {field.type === 'maxLiveCash' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minLiveCash"
                maxFieldname="maxLiveCash"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max Live Cash should be greater than Min Live Cash"
              />
            )}

            {field.type === 'minPrevInstCount' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minPrevInstCount"
                maxFieldname="maxPrevInstCount"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min Prev Q Inst Count should be less than Max Prev Q Inst Count"
              />
            )}
            {field.type === 'maxPrevInstCount' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minPrevQInstCount"
                maxFieldname="maxPrevInstCount"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max Prev Q Inst Count should be greater than Min Prev Q Inst count"
              />
            )}
            {field.type === 'minTotalCount' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minTotalCount"
                maxFieldname="maxTotalCount"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min Total Count should be less than Max Total Count"
              />
            )}
            {field.type === 'maxTotalCount' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minTotalCount"
                maxFieldname="maxTotalCount"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max Total Count should be greater than Min Total Count"
              />
            )}
            {field.type === 'minPosReturnsStockCount' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minPosReturnsStockCount"
                maxFieldname="maxPosReturnsStockCount"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min +ve returns Count should be less than Max +ve returns Count"
              />
            )}
            {field.type === 'maxPosReturnsStockCount' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minPosReturnsStockCount"
                maxFieldname="maxPosReturnsStockCount"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max +ve Returns Count should be greater than Min +ve Return Count"
              />
            )}
            {field.type === 'minNegReturnsStockCount' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minNegReturnsStockCount"
                maxFieldname="maxNegReturnsStockCount"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min -ve returns Count should be less than Max -ve returns Count"
              />
            )}
            {field.type === 'maxNegReturnsStockCount' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minNegReturnsStockCount"
                maxFieldname="maxNegReturnsStockCount"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max -ve Returns Count should be greater than Min -ve Return Count"
              />
            )}
            {field.type === 'minNegReturnsPerc' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minNegReturnsPerc"
                maxFieldname="maxNegReturnsPerc"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min -ve returns % should be less than Max -ve returns %"
              />
            )}
            {field.type === 'maxNegReturnsPerc' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minNegReturnsPerc"
                maxFieldname="maxNegReturnsPerc"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max -ve Returns % should be greater than Min -ve Return %"
              />
            )}
            {field.type === 'minAlloactedPerc' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minAlloactedPerc"
                maxFieldname="maxAlloactedPerc"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min % should be less than Max %"
              />
            )}
            {field.type === 'maxAlloactedPerc' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minAlloactedPerc"
                maxFieldname="maxAlloactedPerc"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max % should be greater than Min %"
              />
            )}
            {field.type === 'minPosReturnsPerc' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minPosReturnsPerc"
                maxFieldname="maxPosReturnsPerc"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min +ve returns % should be less than Max +ve returns %"
              />
            )}
            {field.type === 'maxPosReturnsPerc' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minPosReturnsPerc"
                maxFieldname="maxPosReturnsPerc"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max +ve Returns % should be greater than Min +ve Return %"
              />
            )}
            {field.type === 'minAum' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minAum"
                maxFieldname="maxAum"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min AUM should be less than Max AUM"
              />
            )}
            {field.type === 'maxAum' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minAum"
                maxFieldname="maxAum"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max Aum should be greater than Min Aum"
              />
            )}
            {field.type === 'minPrevAum' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minPrevAum"
                maxFieldname="maxPrevAum"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min Prev AUM should be less than Max Prev AUM"
              />
            )}
            {field.type === 'maxPrevAum' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minPrevAum"
                maxFieldname="maxPrevAum"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max Prev Aum should be greater than Min Prev Aum"
              />
            )}
            {field.type === 'hedgeCount' && (
              <Form.Item
                name={field.name}
                label={field.label}
                style={{ marginBottom: 5 }}
              >
                <InputNumber
                  placeholder={field.placeholder}
                  style={{ width: '90%' }}
                  min={0}
                  formatter={(n) => {
                    if (n?.toString().indexOf('.') > -1) {
                      const [p1] = n?.toString().split('.');
                      let a = `${p1}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                      return a;
                    } else {
                      return `${n}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    }
                  }}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  onFocus={() => setCurrentField('hedge')}
                />
              </Form.Item>
            )}
            {field.type === 'minMarketCap' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minMarketCap"
                maxFieldname="maxMarketCap"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min MarketCap should be less than Max MarketCap"
              />
            )}
            {field.type === 'maxMarketCap' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minMarketCap"
                maxFieldname="maxMarketCap"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max MarketCap should be greater than Min MarketCap"
              />
            )}
            {field.type === 'minLastQ2InstCount' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minLastQ2InstCount"
                maxFieldname="maxLastQ2InstCount"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min Inst Count should be less than Max Inst Count"
              />
            )}
            {field.type === 'maxLastQ2InstCount' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minLastQ2InstCount"
                maxFieldname="maxLastQ2InstCount"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max Inst Count should be greater than Min Inst count"
              />
            )}

            {field.type === 'minTop10HedgeFundsIn_NC_NB_CNT' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minTop10HedgeFundsIn_NC_NB_CNT"
                maxFieldname="maxTop10HedgeFundsIn_NC_NB_CNT"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min Top-10 HF NB/INCR/NC should be less than Max Top-10 HF NB/INCR/NC"
              />
            )}
            {field.type === 'maxTop10HedgeFundsIn_NC_NB_CNT' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minTop10HedgeFundsIn_NC_NB_CNT"
                maxFieldname="maxTop10HedgeFundsIn_NC_NB_CNT"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max Top-10 HF NB/INCR/NC should be greater than Min Top-10 HF NB/INCR/NC"
              />
            )}

            {field.type === 'minTop10InstIn_NC_NB_CNT' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minTop10InstIn_NC_NB_CNT"
                maxFieldname="maxTop10InstIn_NC_NB_CNT"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min Top-10 Inst NB/INCR/NC should be less than Max Top-10 Inst NB/INCR/NC"
              />
            )}
            {field.type === 'maxTop10InstIn_NC_NB_CNT' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minTop10InstIn_NC_NB_CNT"
                maxFieldname="maxTop10InstIn_NC_NB_CNT"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max Top-10 Inst NB/INCR/NC should be greater than Min Top-10 Inst NB/INCR/NC"
              />
            )}
            {field.type === 'minLatestWeekPer' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minLatestWeekPer"
                maxFieldname="maxLatestWeekPer"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Min Latest week per change should be less than Max Latest week per change"
              />
            )}
            {field.type === 'maxLatestWeekPer' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minLatestWeekPer"
                maxFieldname="maxLatestWeekPer"
                currentField={currentField}
                setCurrentField={setCurrentField}
                min={0}
                errorMessage="Max Latest week per change should be greater than Min Latest week per change"
              />
            )}

            {field.type === 'minOneDayChange' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minOneDayChange"
                maxFieldname="maxOneDayChange"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min 1 Day Change should be less than Max 1 Day Change"
              />
            )}
            {field.type === 'maxOneDayChange' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minOneDayChange"
                maxFieldname="maxOneDayChange"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max 1 Day Change should be greater than Min 1 Day Change"
              />
            )}
            {field.type === 'min1DMA' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="min1DMA"
                maxFieldname="max1DMA"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min 1 DMA should be less than Max 1 DMA"
              />
            )}
            {field.type === 'min5DMA' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="min5DMA"
                maxFieldname="max5DMA"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min 5 DMA should be less than Max 5 DMA"
              />
            )}
            {field.type === 'min10DMA' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="min10DMA"
                maxFieldname="max10DMA"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min 10 DMA should be less than Max 10 DMA"
              />
            )}
            {field.type === 'min20DMA' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="min20DMA"
                maxFieldname="max20DMA"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min 20 DMA should be less than Max 20 DMA"
              />
            )}
            {field.type === 'min50DMA' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="min50DMA"
                maxFieldname="max50DMA"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min 50 DMA should be less than Max 50 DMA"
              />
            )}
            {field.type === 'min100DMA' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="min100DMA"
                maxFieldname="max100DMA"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min 100 DMA should be less than Max 100 DMA"
              />
            )}
            {field.type === 'min150DMA' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="min150DMA"
                maxFieldname="max150DMA"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min 150 DMA should be less than Max 150 DMA"
              />
            )}
            {field.type === 'min200DMA' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="min200DMA"
                maxFieldname="max200DMA"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min 200 DMA should be less than Max 200 DMA"
              />
            )}
            {field.type === 'minGain' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minGain"
                maxFieldname="maxGain"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min % Gain should be less than Max % Gain"
              />
            )}
            {field.type === 'maxGain' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minGain"
                maxFieldname="maxGain"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max % Gain should be greater than Min % Gain"
              />
            )}
            {field.type === 'minLoss' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minLoss"
                maxFieldname="maxLoss"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min % Loss should be less than Max % Gain"
              />
            )}
            {field.type === 'maxLoss' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minLoss"
                maxFieldname="maxLoss"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max % Loss should be greater than Min % Loss"
              />
            )}
            {field.type === 'minDayGain' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minDayGain"
                maxFieldname="maxDayGain"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min % Gain should be less than Max % Gain"
              />
            )}
            {field.type === 'maxDayGain' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minDayGain"
                maxFieldname="maxDayGain"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max % Gain should be greater than Min % Gain"
              />
            )}
            {field.type === 'minDayLoss' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minDayLoss"
                maxFieldname="maxDayLoss"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min % Loss should be less than Max % Gain"
              />
            )}
            {field.type === 'maxDayLoss' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minDayLoss"
                maxFieldname="maxDayLoss"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max % Loss should be greater than Min % Gain"
              />
            )}

            {field.type === 'minVolGain' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minVolGain"
                maxFieldname="maxVolGain"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min % Gain should be less than Max % Gain"
              />
            )}
            {field.type === 'maxVolGain' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minVolGain"
                maxFieldname="maxVolGain"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max % Gain should be greater than Min % Gain"
              />
            )}
            {field.type === 'minVolLoss' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minVolLoss"
                maxFieldname="maxVolLoss"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min % Loss should be less than Max % Gain"
              />
            )}
            {field.type === 'maxVolLoss' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minVolLoss"
                maxFieldname="maxVolLoss"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max % Loss should be greater than Min % Gain"
              />
            )}

            {field.type === 'minVolDiff' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minVolDiff"
                maxFieldname="maxVolDiff"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min Vol Diff % should be less than Max Vol Diff %"
              />
            )}
            {field.type === 'maxVolDiff' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minVolDiff"
                maxFieldname="maxVolDiff"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max Vol Diff % should be greater than Min Vol Diff %"
              />
            )}
            {field.type === 'minCountChange' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minCountChange"
                maxFieldname="maxCountChange"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min % Counts Change should be less than Max % Counts Change"
              />
            )}
            {field.type === 'maxCountChange' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minCountChange"
                maxFieldname="maxCountChange"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max % Counts Change should be greater than Min % Counts Change"
              />
            )}
            {field.type === 'weeklyMinInput' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="weeklyMinInput"
                maxFieldname="weeklyMaxInput"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min  should be less than Max"
              />
            )}
            {field.type === 'weeklyMaxInput' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="weeklyMinInput"
                maxFieldname="weeklyMaxInput"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max should be greater than Min"
              />
            )}
            {field.type === 'minStudentInput' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minStudentInput"
                maxFieldname="maxStudentInput"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage={`${field.name} should be less than ${
                  maxFieldNames[field.name]
                }`}
              />
            )}
            {field.type === 'maxStudentInput' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minStudentInput"
                maxFieldname="maxStudentInput"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage={`${field.name} should be greater than ${
                  minFieldNames[field.name]
                }`}
              />
            )}
            {field.type === 'minPossibleGrowth' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minPossibleGrowth"
                maxFieldname="maxPossibleGrowth"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min % Possible Growth should be less than Max % Possible Growth"
              />
            )}
            {field.type === 'maxPossibleGrowth' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minPossibleGrowth"
                maxFieldname="maxPossibleGrowth"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max % Possible Growth should be greater than Min % Possible Growth"
              />
            )}
            {field.type === 'minRank' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minRank"
                maxFieldname="maxRank"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min Rank should be less than Max Rank"
              />
            )}
            {field.type === 'maxRank' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minRank"
                maxFieldname="maxRank"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max Rank should be greater than Min Rank"
              />
            )}
            {field.type === 'minPercent' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minPercent"
                maxFieldname="maxPercent"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min % should be less than Max %"
              />
            )}
            {field.type === 'maxPercent' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minPercentage"
                maxFieldname="maxPercent"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max % should be greater than Min %"
              />
            )}
            {field.type === 'minSharesChange' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minSharesChange"
                maxFieldname="maxSharesChange"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min % Shares Change should be less than Max % Shares Change"
              />
            )}
            {field.type === 'maxSharesChange' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minSharesChange"
                maxFieldname="maxSharesChange"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max % Shares Change should be greater than Min % Shares Change"
              />
            )}
            {field.type === 'minTotalSharesChange' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minTotalSharesChange"
                maxFieldname="maxTotalSharesChange"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min % Total Shares Change should be less than Max % Total Shares Change"
              />
            )}
            {field.type === 'maxTotalSharesChange' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minTotalSharesChange"
                maxFieldname="maxTotalSharesChange"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max % Total Shares Change should be greater than Min % Total Shares Change"
              />
            )}
            {field.type === 'minVolumeChange' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minVolumeChange"
                maxFieldname="maxVolumeChange"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min % Avg Volume Change should be less than Max % Avg Volume Change"
              />
            )}
            {field.type === 'maxVolumeChange' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minVolumeChange"
                maxFieldname="maxVolumeChange"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max % Avg Volume Change should be greater than Min % Avg Volume Change"
              />
            )}
            {field.type === 'minAvgChange' && (
              <MinField
                Form={Form}
                field={field}
                minFieldName="minAvgChange"
                maxFieldname="maxAvgChange"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Min % Avg Price Change should be less than Max % Avg Price Change"
              />
            )}
            {field.type === 'maxAvgChange' && (
              <MaxField
                Form={Form}
                field={field}
                minFieldName="minAvgChange"
                maxFieldname="maxAvgChange"
                currentField={currentField}
                setCurrentField={setCurrentField}
                errorMessage="Max % Avg Price Change should be greater than Min % Avg Price Change"
              />
            )}
            {field.type === 'inputField' && (
              <Form.Item
                name={field.name}
                style={{ marginBottom: 5 }}
                label={field.label}
              >
                <InputNumber
                  style={{ width: '90%' }}
                  placeholder={field.placeholder}
                  type={field.allowDecimal ? '' : 'number'}
                  // min={field.allowNegative ? '' : 0}
                  formatter={(n) => formatter(n, false)}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            )}
            {field.type === 'inputFieldPositive' && (
              <Form.Item
                name={field.name}
                style={{ marginBottom: 5 }}
                label={field.label}
              >
                <InputNumber
                  style={{ width: '90%' }}
                  placeholder={field.placeholder}
                  type={field.allowDecimal ? '' : 'number'}
                  min={0}
                />
              </Form.Item>
            )}
            {field.type === 'selectListField' && (
              <Form.Item
                name={field.name}
                label={field.placeholder}
                style={{ marginBottom: 5 }}
                mode={field.multiple ? 'multiple' : false}
              >
                <Select
                  placeholder={field.placeholder}
                  style={{ width: '90%' }}
                >
                  {selectList(field.data)}
                </Select>
              </Form.Item>
            )}
            {field.type === 'selectObjectField' && (
              <Form.Item
                name={field.name}
                style={{ marginBottom: 5 }}
                label={field.placeholder}
              >
                <Select
                  placeholder={field.placeholder}
                  style={{ width: '90%' }}
                  queryDate
                  mode={field.multiple ? 'multiple' : false}
                >
                  {selectObjectList(field.data)}
                </Select>
              </Form.Item>
            )}
            {field.type === 'date' && (
              <Form.Item
                name="fromDate"
                label="From Date For Price"
                style={{ width: '100%', marginBottom: 5 }}
              >
                <DatePicker
                  disabledDate={(currentDate: Date) =>
                    currentDate >=
                      moment().tz('America/New_York').subtract(1, 'day') ||
                    currentDate <=
                      moment().tz('America/New_York').subtract(1, 'year') ||
                    moment(currentDate).day() === 0 ||
                    moment(currentDate).day() === 6 ||
                    checkData(currentDate)
                  }
                  // onChange={onChange}
                  // defaultValue={date}
                  clearIcon={false}
                  // format={'DD MMM YYYY'}
                  className={styles.vDatePickerNew1}
                />
              </Form.Item>
            )}
            {field.type === 'eventDate' && (
              <Form.Item
                name="eventDate"
                label="Event Date"
                style={{ width: '100%', marginBottom: 5 }}
              >
                <DatePicker
                  // disabledDate={(currentDate: Date) =>
                  //   currentDate >=
                  //     moment().tz('America/New_York').subtract(1, 'day') ||
                  //   currentDate <=
                  //     moment().tz('America/New_York').subtract(1, 'year') ||
                  //   moment(currentDate).day() === 0 ||
                  //   moment(currentDate).day() === 6 ||
                  //   checkData(currentDate)
                  // }
                  // onChange={onChange}
                  // defaultValue={date}
                  clearIcon={false}
                  // format={'DD MMM YYYY'}
                  className={styles.vDatePickerNew1}
                />
              </Form.Item>
            )}
            {field.type === 'queryDate' && (
              <Form.Item
                name="queryDate"
                label="Date"
                style={{ width: '100%', marginBottom: 5 }}
              >
                <DatePicker
                  disabledDate={(currentDate: Date) =>
                    currentDate >=
                      moment().tz('America/New_York').subtract(1, 'day') ||
                    currentDate <=
                      moment().tz('America/New_York').subtract(1, 'year') ||
                    moment(currentDate).day() === 0 ||
                    moment(currentDate).day() === 6 ||
                    checkData(currentDate)
                  }
                  // onChange={onChange}
                  // defaultValue={date}
                  clearIcon={false}
                  // format={'DD MMM YYYY'}
                  className={styles.vDatePickerNew1}
                />
              </Form.Item>
            )}
            {field.type === 'dateRange' && (
              <Row span={24}>
                <Col span={12}>
                  <Form.Item
                    name="startDate"
                    label="Start Date"
                    style={{ width: '100%', marginBottom: 5 }}
                  >
                    <DatePicker
                      disabledDate={(currentDate: Date) =>
                        currentDate > moment().tz('America/New_York') ||
                        currentDate <=
                          moment().tz('America/New_York').subtract(1, 'year') ||
                        moment(currentDate).day() === 0 ||
                        moment(currentDate).day() === 6 ||
                        checkData(currentDate)
                      }
                      // onChange={onChange}
                      // defaultValue={date}
                      clearIcon={false}
                      // format={'DD MMM YYYY'}
                      className={styles.vDatePickerNew1}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="endDate"
                    label="End Date"
                    style={{ width: '100%', marginBottom: 5 }}
                  >
                    <DatePicker
                      disabledDate={(currentDate: Date) =>
                        currentDate > moment().tz('America/New_York') ||
                        currentDate <=
                          moment().tz('America/New_York').subtract(1, 'year') ||
                        moment(currentDate).day() === 0 ||
                        moment(currentDate).day() === 6 ||
                        checkData(currentDate)
                      }
                      // onChange={onChange}
                      // defaultValue={date}
                      clearIcon={false}
                      // format={'DD MMM YYYY'}
                      className={styles.vDatePickerNew1}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {field.type === 'entryDateRange' && (
              <Row span={24}>
                <Col span={12}>
                  <Form.Item
                    name="entryStartDate"
                    label="Entry Start Date"
                    style={{ width: '100%', marginBottom: 5 }}
                  >
                    <DatePicker
                      disabledDate={(currentDate: Date) =>
                        currentDate > moment().tz('America/New_York') ||
                        moment(currentDate).day() === 0 ||
                        moment(currentDate).day() === 6 ||
                        checkData(currentDate)
                      }
                      // onChange={onChange}
                      // defaultValue={date}
                      clearIcon={false}
                      // format={'DD MMM YYYY'}
                      className={styles.vDatePickerNew1}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="entryEndDate"
                    label="Entry End Date"
                    style={{ width: '100%', marginBottom: 5 }}
                  >
                    <DatePicker
                      disabledDate={(currentDate: Date) =>
                        currentDate > moment().tz('America/New_York') ||
                        moment(currentDate).day() === 0 ||
                        moment(currentDate).day() === 6 ||
                        checkData(currentDate)
                      }
                      // onChange={onChange}
                      // defaultValue={date}
                      clearIcon={false}
                      // format={'DD MMM YYYY'}
                      className={styles.vDatePickerNew1}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {field.type === 'weeklyDateRange' && (
              <Row span={24}>
                <Col span={12}>
                  <Form.Item
                    name="startDate"
                    label="Start Date"
                    style={{ width: '100%', marginBottom: 5 }}
                  >
                    <DatePicker
                      onChange={(e) => setDisableStart(e)}
                      disabledDate={(currentDate: Date) =>
                        currentDate > moment().tz('America/New_York') ||
                        moment(currentDate).day() === 0 ||
                        moment(currentDate).day() === 6 ||
                        checkDataFrom(currentDate)
                      }
                      // onChange={onChange}
                      // defaultValue={date}
                      clearIcon={false}
                      // format={'DD MMM YYYY'}
                      className={styles.vDatePickerNew1}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="endDate"
                    label="End Date"
                    style={{ width: '100%', marginBottom: 5 }}
                  >
                    <DatePicker
                      disabledDate={(currentDate: Date) =>
                        currentDate > moment().tz('America/New_York') ||
                        moment(currentDate).day() === 0 ||
                        moment(currentDate).day() === 6 ||
                        checkDataTo(currentDate)
                      }
                      // onChange={onChange}
                      // defaultValue={date}
                      clearIcon={false}
                      // format={'DD MMM YYYY'}
                      className={styles.vDatePickerNew1}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>
        ))}
      </Row>
      <Row span={24} justify="center" className={styles.buttonSectionBoxNew}>
        <Form.Item colon={false} style={{ margin: 0, marginTop: 20 }}>
          <Button type="primary" htmlType="submit" style={{ marginRight: 20 }}>
            Submit
          </Button>
          <Button
            htmlType="button"
            onClick={onReset}
            // disabled={!isResetBtnDisable()}
          >
            Reset
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default FilterForm;
